export const getUrl = (which) => {
  return `${process.env.REACT_APP_BASE_URL}/${process.env[which]}`;
};

export const getStripeUrl = (which) => {
  return `${process.env.REACT_APP_STRIPE_BASE_URL}/${process.env[which]}`;
};

export const getCmsUrl = (which) =>
  `${process.env.REACT_APP_CMS_BASE_URL}/${process.env[which]}`;
