import { Container, Col, Row, Button, Spinner } from "react-bootstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ErrorModal from "./ErrorModal";
import { getUrl, getStripeUrl } from "../helpers/url";

const ManagePlan = () => {
  const [searchParams] = useSearchParams();
  const [currentPlan, setCurrentPlan] = useState({});
  const [companyId, setCompanyId] = useState("");
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const fetchPlanDetails = async () => {
    try {
      setLoading(true);
      const companyId = searchParams.get("companyId");
      const userId = searchParams.get("userId");

      setCompanyId(companyId);
      setUserId(userId);

      let { data: subscriptionData } = await axios.get(
        getUrl("REACT_APP_GET_PLAN_DATA_URL"),
        {
          params: { userId, companyId },
        }
      );
      if (!subscriptionData.plan)
        throw new Error(
          "Error while getting plan. Please try again after sometime"
        );
      setCurrentPlan(subscriptionData);
      setLoading(false);
    } catch (error) {
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    }
  };

  const openStripePortal = async () => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    try {
      setBtnLoading(true);
      const { data: response } = await axios.post(
        getStripeUrl("REACT_APP_STRIPE_BILLING_PORTAL"),
        {
          customerKey: currentPlan.customerKey,
          returnUrl: `${window.location.origin}/setup?companyId=${companyId}&userId=${userId}&paymentStatus=none`,
        }
      );
      if (response.url) {
        window.open(response.url, "_blank");
      }
    } catch (error) {
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    } finally {
      setBtnLoading(false);
    }
  };

  const planName = () => {
    if (currentPlan.plan.includes("breached")) {
      const planName = currentPlan.plan.replace("-breached", "");
      return (
        <span>
          <span className="current-plan-heading">
            {planName[0].toUpperCase() + planName.substring(1)}
          </span>{" "}
          - Plan expired upgrade your plan.
        </span>
      );
    } else {
      return currentPlan.plan[0].toUpperCase() + currentPlan.plan.substring(1);
    }
  };

  const hideErrorModal = () => setShowErrorModal(false);

  useEffect(() => {
    fetchPlanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorModal
        show={showErrorModal}
        message={errorMessage}
        hideErrorModal={hideErrorModal}
      />
      {loading ? (
        <div className="d-flex flex-column justify-content-center align-items-center loading-container">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="success" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <Container className="my-5">
          <Row>
            <Col>
              <div className="p-4 d-flex flex-row justify-content-between align-items-center current-plan-container">
                <div>
                  <p className="current-plan-heading my-2">
                    Current plan{" "}
                    {currentPlan && Object.keys(currentPlan).length > 0 && (
                      <span className="current-plan-name ms-3">
                        {planName()}
                      </span>
                    )}
                  </p>
                  <p className="current-plan-heading my-2">
                    Usage Count{" "}
                    {currentPlan && Object.keys(currentPlan).length > 0 && (
                      <span className="current-plan-name ms-3">
                        {currentPlan.usageCount}
                      </span>
                    )}
                  </p>
                </div>
                <div>
                  <Button className="add-mapping-button me-3">
                    <a
                      href={`${window.location.origin}/subscription?companyId=${companyId}&userId=${userId}&paymentStatus=none`}
                      target="_blank"
                      className="manage-plan-btn"
                      rel="noreferrer"
                    >
                      Manage plan
                    </a>
                  </Button>
                  {currentPlan.customerKey && (
                    <Button
                      className="add-mapping-button"
                      onClick={() => openStripePortal()}
                    >
                      {btnLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>
                          View billing details
                          <FaExternalLinkAlt
                            style={{ marginTop: "-3px" }}
                            className="ms-2"
                          />
                        </span>
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ManagePlan;
