import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import { useDirty } from "../context/dirty";

function ErrorModal({ show, message, hideErrorModal }) {
  const { cmsInstance } = useDirty();

  useEffect(() => {
    // Added timer for hiding the error modal
    if (show) {
      const timer = setTimeout(() => {
        hideErrorModal();
      }, 3000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const checkContentFrom = () => {
    const notificationMessage = message.split(".");
    const messageFrom = notificationMessage.includes("cmsContent");
    return messageFrom;
  };

  return (
    <div>
      <Modal className="my-modal" onHide={hideErrorModal} show={show}>
        <div className="error-modal">
          <div className="d-flex flex-row justify-content-end">
            <Button className="error-modal-button" onClick={hideErrorModal}>
              ×
            </Button>
          </div>
          {/* <p className="error-modal-message mb-4 mx-3">{message}</p> */}
          {checkContentFrom() ? (
            <>
              {cmsInstance &&
                cmsInstance.showRichText(message, "error-modal-message")}
            </>
          ) : (
            <p className="error-modal-message mb-4 mx-3">{message}</p>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ErrorModal;
