import React from "react";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

function PDSelectSearch({
  onChangePipedriveSelect,
  disablePipedriveValue,
  enableEditing,
  onHandlePDSelect,
  selectDefaultProperty,
  mappedItem,
  isCVRCheck,
  mappings,
  cvrValue,
  editCVRValue,
  item,
  pdProperties,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [pdEditedValue, setPDEditedValue] = useState("");

  // This function is responsible for assigning the pipedrive label from dropdown while editing existing mapping
  const onEditSelectedValue = (pdValue) => {
    let selectedItem = {};
    pdProperties.forEach((pdObject) => {
      if (pdObject.value === pdValue) {
        selectedItem = pdObject;
      }
    });

    setPDEditedValue(selectedItem.label);
    onHandlePDSelect(selectedItem);
  };

  // This function is responsible for searching pipedrive labels in the dropdown when user enters value in the input field.
  const onSearchItems = (inputValue) => {
    setSearchInput(inputValue);
    const filteredData = pdProperties.filter((pipedriveField) => {
      return pipedriveField.label
        .toLowerCase()
        .includes(inputValue.toLowerCase());
    });

    setSearchResults(filteredData);
  };

  // This function is responsible for validating and disabling Pipedrive fields
  const disablePDFields = (pdProperty, cvrFieldType) => {
    if (cvrValue && cvrValue.length > 0 && cvrValue[1]?.type) {
      if (cvrValue[1].type === "string" || cvrValue[1].type === "boolean") {
        return (
          pdProperty.type === "text" ||
          pdProperty.type === "varchar" ||
          pdProperty.type === "varchar_auto" ||
          pdProperty.type === "phone" ||
          pdProperty.type === "address"
        );
      } else if (cvrValue[1].type === "number") {
        return (
          pdProperty.type === "text" ||
          pdProperty.type === "varchar" ||
          pdProperty.type === "varchar_auto" ||
          pdProperty.type === "phone" ||
          pdProperty.type === "address" ||
          pdProperty.type === "double"
        );
      } else if (cvrValue[1].type === "date") {
        return (
          pdProperty.type === "text" ||
          pdProperty.type === "varchar" ||
          pdProperty.type === "varchar_auto" ||
          pdProperty.type === "phone" ||
          pdProperty.type === "address" ||
          pdProperty.type === "date"
        );
      }
    } else if (
      editCVRValue &&
      editCVRValue.length > 0 &&
      editCVRValue[1]?.type
    ) {
      if (
        editCVRValue[1].type === "string" ||
        editCVRValue[1].type === "boolean"
      ) {
        return (
          pdProperty.type === "text" ||
          pdProperty.type === "varchar" ||
          pdProperty.type === "varchar_auto" ||
          pdProperty.type === "phone" ||
          pdProperty.type === "address"
        );
      } else if (editCVRValue[1].type === "number") {
        return (
          pdProperty.type === "text" ||
          pdProperty.type === "varchar" ||
          pdProperty.type === "varchar_auto" ||
          pdProperty.type === "phone" ||
          pdProperty.type === "address" ||
          pdProperty.type === "double"
        );
      } else if (editCVRValue[1].type === "date") {
        return (
          pdProperty.type === "text" ||
          pdProperty.type === "varchar" ||
          pdProperty.type === "varchar_auto" ||
          pdProperty.type === "phone" ||
          pdProperty.type === "address" ||
          pdProperty.type === "date"
        );
      }
    } else if (cvrFieldType && editCVRValue && editCVRValue.length === 0) {
      if (cvrFieldType === "string" || cvrFieldType === "boolean") {
        return (
          pdProperty.type === "text" ||
          pdProperty.type === "varchar" ||
          pdProperty.type === "varchar_auto" ||
          pdProperty.type === "phone" ||
          pdProperty.type === "address"
        );
      } else if (cvrFieldType === "number") {
        return (
          pdProperty.type === "text" ||
          pdProperty.type === "varchar" ||
          pdProperty.type === "varchar_auto" ||
          pdProperty.type === "phone" ||
          pdProperty.type === "address" ||
          pdProperty.type === "double"
        );
      } else if (cvrFieldType === "date") {
        return (
          pdProperty.type === "text" ||
          pdProperty.type === "varchar" ||
          pdProperty.type === "varchar_auto" ||
          pdProperty.type === "phone" ||
          pdProperty.type === "address" ||
          pdProperty.type === "date"
        );
      }
    } else if (pdProperty.type === "enum") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      {isCVRCheck ? (
        // Pipedrive Dropdown for mapping the CVR Number field
        <Dropdown onSelect={onEditSelectedValue}>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="dropdown-button dropdown-select"
          >
            {pdEditedValue === "" ? "Select" : pdEditedValue}
          </Dropdown.Toggle>

          <Dropdown.Menu
            renderOnMount={true}
            popperConfig={{ strategy: "fixed" }}
            className="dropdown-container"
          >
            <div className="p-3 dropdown-search-container">
              <div className="d-flex flex-row dropdown-search-box">
                <span className="my-auto">
                  <FaSearch size={15} className="mx-2 dropdown-search-icon" />
                </span>
                <input // input for searching pipedrive labels from the dropdown
                  type="search"
                  placeholder="Search.."
                  className="w-100 dropdown-search"
                  onChange={(e) => onSearchItems(e.target.value)}
                />
              </div>
            </div>
            <div className="dropdown-items-container">
              {(searchInput.length > 1 ? searchResults : pdProperties)
                // custom sorting function by comparing values as first and second
                .sort((first, second) =>
                  first.label.localeCompare(second.label)
                )
                .map((pdObject) => (
                  // List of Pipedrive labels inside dropdown
                  <Dropdown.Item
                    className={
                      "select-options " +
                      (disablePipedriveValue(item, pdObject.value) ||
                      !disablePDFields(pdObject, mappedItem[1].type)
                        ? "disabled-option "
                        : "")
                    }
                    disabled={
                      disablePipedriveValue(item, pdObject.value) ||
                      !disablePDFields(pdObject, mappedItem[1].type)
                    }
                    key={pdObject.value}
                    eventKey={pdObject.value}
                    value={pdObject.value}
                  >
                    {pdObject.label}
                  </Dropdown.Item>
                ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        // Pipedrive Dropdown for edit mapping component
        <Dropdown onSelect={onEditSelectedValue}>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="dropdown-button dropdown-select"
          >
            {pdEditedValue === "" ? "Select" : pdEditedValue}
          </Dropdown.Toggle>

          <Dropdown.Menu
            renderOnMount={true}
            popperConfig={{ strategy: "fixed" }}
            className="dropdown-container"
          >
            <div className="p-3 dropdown-search-container">
              <div className="d-flex flex-row dropdown-search-box">
                <span className="my-auto">
                  <FaSearch size={15} className="mx-2 dropdown-search-icon" />
                </span>
                <input // input for searching pipedrive labels from the dropdown
                  type="search"
                  placeholder="search.."
                  className="w-100 dropdown-search"
                  onChange={(e) => onSearchItems(e.target.value)}
                />
              </div>
            </div>
            <div className="dropdown-items-container">
              {(searchInput.length > 1 ? searchResults : pdProperties)
                // custom sorting function by comparing values as first and second
                .sort((first, second) =>
                  first.label.localeCompare(second.label)
                )
                .map((pdObject) => (
                  // List of Pipedrive labels inside dropdown
                  <Dropdown.Item
                    className={
                      "select-options " +
                      ((disablePipedriveValue(item, pdObject.value) &&
                        pdObject.value !==
                          selectDefaultProperty(mappedItem[1]).pdValue) ||
                      !disablePDFields(pdObject, mappedItem[1].type)
                        ? "disabled-option "
                        : "")
                    }
                    disabled={
                      (disablePipedriveValue(item, pdObject.value) &&
                        pdObject.value !==
                          selectDefaultProperty(mappedItem[1]).pdValue) ||
                      !disablePDFields(pdObject, mappedItem[1].type)
                    }
                    selected={
                      pdObject.value ===
                      selectDefaultProperty(mappedItem[1]).pdValue
                    }
                    eventKey={pdObject.value}
                    key={pdObject.value}
                    value={pdObject.value}
                  >
                    {pdObject.label}
                  </Dropdown.Item>
                ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

export default PDSelectSearch;
