import React from "react";
import { useSearchParams } from "react-router-dom";
import { Offcanvas, Button, Spinner } from "react-bootstrap";
import { useState } from "react";
import { BiCodeAlt } from "react-icons/bi";
import axios from "axios";

import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

import { getUrl } from "../helpers/url";

function SideBar({ getPDFields, defaultMappings, activeItem }) {
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // These functions are responsible for showing Sidebar component
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // This function is responsible for creating new Pipedrive property.
  const onCreatePipedriveProperty = async () => {
    if (fieldName !== "" && fieldName !== undefined) {
      try {
        setLoading(true);
        // Getting params from the url
        const companyId = searchParams.get("companyId");
        const userId = searchParams.get("userId");
        const data = {
          userId: userId,
          companyId: companyId,
          name: fieldName,
        };

        let { data: res } = await axios.post(
          getUrl("REACT_APP_CREATE_FIELD_URL"),
          data
        );
        // Calling fetchMappings to update the states, as the newly created pipedrive property also gets added in the dropdown
        await getPDFields();
        setMessage(res.message);
        setShowSuccessModal(true);
      } catch (error) {
        console.log(error);
        let errorMessage;
        if (error.response && error.response.data.message)
          errorMessage = error.response.data.message;
        else errorMessage = error.message;
        setMessage(errorMessage);
        setShowErrorModal(true);
      }
      setLoading(false);
      setFieldName("");
      setShow(false);
    }
  };

  // const disableCreateNewPDFieldBtn = () => {
  //   if (defaultMappings && Object.keys(defaultMappings).length > 0) {
  //     if (defaultMappings["basicMappings"]["CVR Number"].pdValue === "") {
  //       return false;
  //     } else if (isDirty) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  // These functions are responsible for showing Success and Error Modals
  const hideSuccessModal = () => setShowSuccessModal(false);
  const hideErrorModal = () => setShowErrorModal(false);

  return (
    <div>
      <SuccessModal
        show={showSuccessModal}
        message={message}
        hideSuccessModal={hideSuccessModal}
      />
      <ErrorModal
        show={showErrorModal}
        message={message}
        hideErrorModal={hideErrorModal}
      />
      <button className="sidebar-button my-3 my-md-0 px-3" onClick={handleShow}>
        Create new Pipedrive property
      </button>

      <Offcanvas
        show={show}
        placement="end"
        onHide={handleClose}
        className="p-3"
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="sidebar-heading">
            Create new property
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div>
              <label className="form-label" htmlFor="customName">
                Name
              </label>
              <div className="d-flex flex-row">
                <input
                  id="customName"
                  type="text"
                  onChange={(e) => setFieldName(e.target.value)}
                  className="sidebar-input form-control"
                />
                <span className="mx-2 my-auto">
                  <BiCodeAlt size={30} className="code-icon" />
                </span>
              </div>
              <p className="hint-text mt-2">
                *The name of your Pipedrive property.
              </p>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="sidebar-footer d-flex flex-column justify-content-center align-items-center">
          <div className="w-100 px-3 d-flex justify-content-between">
            <button onClick={handleClose} className="sidebar-cancel-button">
              Cancel
            </button>
            <Button
              disabled={fieldName === "" || fieldName.startsWith(" ")}
              onClick={onCreatePipedriveProperty}
              className="sidebar-create-button"
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Create</span>
              )}
            </Button>
          </div>
        </div>
      </Offcanvas>
    </div>
  );
}

export default SideBar;
