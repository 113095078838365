import React from "react";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";

function MappingWindowHeader({
  isCVRSort,
  isPDSort,
  onSortCVRFields,
  onSortPDFields,
}) {
  return (
    <tr>
      <th
        className={
          "mapping-header-label " +
          (isCVRSort !== undefined && "selected-header ")
        }
        onClick={onSortCVRFields}
        style={{ width: "500px" }}
      >
        <div className="my-auto ms-3">
          CVR
          <span className="ms-1">
            {isCVRSort === true && <TiArrowSortedUp className="sort-icon" />}
            {isCVRSort === false && <TiArrowSortedDown className="sort-icon" />}
          </span>
        </div>
      </th>
      <th
        className={
          "mapping-header-label " +
          (isPDSort !== undefined && "selected-header ")
        }
        onClick={onSortPDFields}
      >
        <div className="my-auto ms-3">
          Pipedrive
          <span className="ms-1">
            {isPDSort === true && <TiArrowSortedUp className="sort-icon" />}
            {isPDSort === false && <TiArrowSortedDown className="sort-icon" />}
          </span>
        </div>
      </th>
      <th className="text-center mapping-header-label actions-container">
        <div className="pe-2">Actions</div>
      </th>
    </tr>
  );
}

export default MappingWindowHeader;
