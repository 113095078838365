import { useEffect } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";

import MappingWindow from "./MappingWindow";
import ManagePlan from "../components/ManagePlan";
import SuccessModal from "../components/SuccessModal";

import { useDirty } from "../context/dirty";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

const IframeWindow = () => {
  const {
    setCmsInstance,
    setMessage,
    setErrorModal,
    successModal,
    hideSuccModal,
    message,
  } = useDirty();

  useEffect(() => {
    if (window.location.pathname === "/iframewindow") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor({ setMessage, setErrorModal });
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SuccessModal
        show={successModal}
        message={message}
        hideSuccessModal={hideSuccModal}
      />
      <div className="mapping-window-bg-container py-5">
        {window.location.pathname === "/iframewindow" && (
          <div className="d-flex flex-column align-items-center">
            <CmsEditAndSave />
          </div>
        )}
        <Container>
          <Tabs
            defaultActiveKey="mapping"
            id="uncontrolled-tab-example"
            className="mb-3"
            transition={false}
          >
            <Tab eventKey="mapping" title="Customise mapping">
              <MappingWindow />
            </Tab>
            <Tab eventKey="managePlan" title="Manage plan">
              <ManagePlan />
            </Tab>
          </Tabs>
        </Container>
      </div>
    </>
  );
};

export default IframeWindow;
