import React from "react";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import {
  Button,
  Table,
  Spinner,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
import { FaSearch, FaCaretRight, FaCaretDown } from "react-icons/fa";

import MappingWindowHeader from "./MappingWIndowHeader";
import MappedRows from "./MappedRows";
import EditMapping from "./EditMapping";
import SideBar from "./SideBar";
import SearchResultsNotFound from "./SearchResultsNotFound";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import DeleteModal from "./DeleteModal";
import { useDirty } from "../context/dirty";

import SaveModal from "./SaveModal";

import { getUrl } from "../helpers/url";

function Configurator({ mappings, getPDFields, pdProperties }) {
  const [searchParams] = useSearchParams();
  const {
    setIsDirty,
    showSaveModal,
    hideSaveModal,
    setDefaultMapping,
    setErrorModal,
    setSuccessModal,
    setMessage,
    message,
    errorModal,
    successModal,
    deletedPipedriveFields,
    setDeletedPipedriveFields,
    isCVRCheck,
    setIsCVRCheck,
  } = useDirty();
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState({
    basicMappings: {},
    financialMappings: {},
  });
  const [loading, setLoading] = useState(false);
  const [defaultMappings, setDefaultMappings] = useState({});
  const [enableEditing, setEnableEditing] = useState(false);
  const [storedKey, setStoredKey] = useState("");
  const [editCVRValue, setEditCVRValue] = useState([]);
  const [editPDValue, setEditPDValue] = useState({});
  const isCVRSort = useRef();
  const isPDSort = useRef();
  //const [showSuccessModal, setShowSuccessModal] = useState(false);
  //const [showErrorModal, setShowErrorModal] = useState(false);
  //const [message, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMappedItem, setDeleteMappedItem] = useState("");
  const [activeItem, setActiveItem] = useState("0");
  const [activeKey, setActiveKey] = useState("basicMappings");

  // Filtering the mapping objects into array of objects with keyword and key
  const getFilteredArray = (mappings) => {
    let filteredArray = [];
    Object.entries(mappings).forEach((mapping) => {
      if (deletedPipedriveFields.includes(mapping[1].pdValue)) {
        filteredArray.push({
          keyword: "pipedrive custom property deleted",
          key: mapping[0],
        });
      }
      // Adding mapping into filtered array if their pipedrive property is undefined, to not cause any error while searching or sorting in Mapping window
      if (mapping[1].pdValue === "") {
        filteredArray.push({
          keyword: "",
          key: mapping[0],
        });
      }

      filteredArray.push({
        keyword: mapping[1].pdLabel, // Pipedrive Label
        key: mapping[0], // CVR Label
      });
    });
    return filteredArray;
  };

  // This function is responsible for searching both CVR and Pipedrive fields in Mapping window based on input entered in the search box.
  const onSearchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredArray = getFilteredArray(defaultMappings[activeKey]);

      const filter = filteredArray.filter(({ keyword, key }) => {
        return (
          keyword.toLowerCase().includes(searchValue.toLowerCase()) ||
          key.toLowerCase().includes(searchValue.toLowerCase())
        );
      });

      const filteredData = { basicMappings: {}, financialMappings: {} };
      for (const item of filter) {
        filteredData[activeKey][item.key] =
          defaultMappings[activeKey][item.key];
      }
      setSearchResults(filteredData);
    } else {
      setSearchResults(defaultMappings);
    }
  };

  // This function is responsible for Filtering Mappings Data to default and nonDefault mappings based on the value present in the object.
  const filterDefaultMappings = () => {
    let defaultMappings = {};
    let nonDefaultMappings = {};
    if (Object.keys(mappings).length >= 2) {
      defaultMappings = { basicMappings: {}, financialMappings: {} };
      nonDefaultMappings = { basicMappings: {}, financialMappings: {} };
    } else {
      defaultMappings = { basicMappings: {} };
      nonDefaultMappings = { basicMappings: {} };
    }

    Object.keys(mappings).map((item) => {
      Object.entries(mappings[item]).filter((mappedRow) => {
        if (mappedRow[1].pdValue === "" && !mappedRow[1].required) {
          nonDefaultMappings[item][mappedRow[0]] = mappedRow[1];
        } else {
          defaultMappings[item][mappedRow[0]] = mappedRow[1];
        }
        return false;
      });
      defaultMappings[item] = {
        ...defaultMappings[item],
        ...nonDefaultMappings[item],
      };
      return null;
    });

    setDefaultMappings(defaultMappings);
    setDefaultMapping(defaultMappings);
    // Check Whether CVR Number is mapped in Mapping window or not. If not providing option to map the field
    if (
      !defaultMappings["basicMappings"]["CVR Number"] ||
      defaultMappings["basicMappings"]["CVR Number"].pdValue === ""
    ) {
      setIsCVRCheck(true);
      setEnableEditing(true);
      setStoredKey("CVR Number");
      setIsDirty(true);
      // setEditPDValue("CVR Number");
    } else {
      setIsDirty(false);
    }
  };

  // This function is responsible for getting Pipedrive label and value based on the pipedrive value mapped to CVR field
  const selectDefaultProperty = (value) => {
    if (deletedPipedriveFields.includes(value.pdValue)) {
      return {
        pdLabel: "pipedrive custom property deleted",
        pdValue: "-doesn'texists-",
        type: undefined,
      };
    } else {
      return value;
    }
  };

  const storeActiveAccordion = (eventKey) => {
    setActiveItem(eventKey);
    setEditCVRValue([]);
    setEditPDValue({});
    if (isCVRCheck === false) {
      setStoredKey("");
      setEnableEditing(false);
    }
    setSearchInput("");
    isCVRSort.current = undefined;
    isPDSort.current = undefined;
    if (
      eventKey === "1" &&
      Object.keys(defaultMappings["financialMappings"]).length <= 0
    ) {
      setEnableEditing(false);
    }
  };

  const checkDisablePdValue = (item, pdValue) => {
    if (item === "basicMappings") {
      return Object.entries(defaultMappings["financialMappings"]).find(
        (mapping) => {
          return mapping[1].pdValue === pdValue;
        }
      );
    } else {
      return Object.entries(defaultMappings["basicMappings"]).find(
        (mapping) => {
          return mapping[1].pdValue === pdValue;
        }
      );
    }
  };

  // This function is responsible for disabling pipedrive value in Pipedrive search dropdowns
  const disablePipedriveValue = (item, pdValue) => {
    if (
      item === "financialMappings" &&
      Object.keys(defaultMappings["financialMappings"]).length <= 0
    ) {
      return Object.entries(mappings["basicMappings"]).find((mapping) => {
        return mapping[1].pdValue === pdValue;
      });
    } else if (Object.keys(defaultMappings).length <= 1) {
      return Object.entries(defaultMappings[activeKey]).find((mapping) => {
        return mapping[1].pdValue === pdValue;
      });
    } else {
      return Object.entries(defaultMappings[item]).find((mapping) => {
        return (
          mapping[1].pdValue === pdValue || checkDisablePdValue(item, pdValue)
        );
      });
    }
  };

  // These functions are responsible for assigning selected Pipedrive and CVR values from the dropdown to state variable while editing existing mapping in the mapping window
  const onHandleCVRSelect = (value) => setEditCVRValue(value);
  const onHandlePDSelect = (value) => setEditPDValue(value);

  // This function is responsible for showing the edit mapping component
  const onEditMapping = (value) => {
    setEnableEditing(true);
    setStoredKey(value);
    setIsDirty(true);
  };

  // This function is responsible for cancelling edit mapping by resetting the below variables
  const onCancelMapping = () => {
    setEditCVRValue([]);
    setEditPDValue({});
    setEnableEditing(false);
    setStoredKey("");
    setIsDirty(false);
  };

  // This function is responsible for editing mapped rows in the Mapping window
  const onUpdateMapping = (item, cvrLabel) => {
    if (Object.keys(editPDValue).length > 0) {
      // This condition executes when pipedrive value is changed and cvr value remains unchanged in the dropdown
      Object.entries(defaultMappings[item]).forEach((defaultMapping) => {
        if (defaultMapping[0] === cvrLabel) {
          defaultMappings[item][defaultMapping[0]] = {
            pdLabel: editPDValue.label,
            pdValue: editPDValue.value,
            cvrFieldDescription: defaultMapping[1].cvrFieldDescription,
            type: defaultMapping[1].type,
            required:
              (cvrLabel === "CVR Number" || cvrLabel === "Name") && true,
          };
          setDefaultMappings({ ...defaultMappings });
          setDefaultMapping({ ...defaultMappings });
        }
      });
    }
    if (defaultMappings["basicMappings"]["CVR Number"].pdValue !== "")
      setIsCVRCheck(false);
    onSearchItems(searchInput);
    // Resetting the values after editing the mapping
    setEnableEditing(false);
    setStoredKey("");
    setEditCVRValue([]);
    setEditPDValue({});
    setIsDirty(true);
    if (isCVRSort.current !== undefined) {
      // performs the sort for cvr fields if isCVRSort not equal to undefined after editing existing mapping
      isCVRSort.current = !isCVRSort.current;
      onSortCVRFields();
    }
    if (isPDSort.current !== undefined) {
      // performs the sort for pd fields if isPDSort not equal to undefined after editing existing mapping
      isPDSort.current = !isPDSort.current;
      onSortPDFields();
    }
  };

  // This function is responsible for deleting the mappings from Mapping window
  const onDeleteExistingMapping = () => {
    defaultMappings[activeKey][deleteMappedItem] = {
      ...defaultMappings[activeKey][deleteMappedItem],
      pdLabel: "",
      pdValue: "",
    };
    setDefaultMappings({ ...defaultMappings });
    setDefaultMapping({ ...defaultMappings });
    setIsDirty(true);
  };

  // This function is responsible for sorting the CVR fields in Mapping window in both ascending and descending order
  const onSortCVRFields = () => {
    isPDSort.current = undefined;
    if (isCVRSort.current === undefined) {
      isCVRSort.current = true;
    } else {
      isCVRSort.current = !isCVRSort.current;
    }

    if (isCVRSort.current === true) {
      // performs sorting for cvr fields in ascending order
      let asc = {};
      if (Object.keys(defaultMappings).length <= 1) {
        asc = { basicMappings: {} };
      } else {
        if (activeKey === "basicMappings") {
          asc = {
            basicMappings: {},
            financialMappings: { ...defaultMappings["financialMappings"] },
          };
        } else {
          asc = {
            basicMappings: { ...defaultMappings["basicMappings"] },
            financialMappings: {},
          };
        }
      }
      // asc = { basicMappings: {}, financialMappings: {} };
      // const asc = {}
      // custom sorting function by comparing values as first and second inside the filtered array
      Object.entries(
        searchInput.length > 1
          ? searchResults[activeKey]
          : defaultMappings[activeKey]
      )
        .sort((first, second) => (first > second ? 1 : -1))
        .forEach((mapping) => {
          asc[activeKey][mapping[0]] = mapping[1];
        });
      searchInput.length > 1 ? setSearchResults(asc) : setDefaultMappings(asc);
    } else {
      let desc;
      if (Object.keys(defaultMappings).length <= 1) {
        desc = { basicMappings: {} };
      } else {
        if (activeKey === "basicMappings") {
          desc = {
            basicMappings: {},
            financialMappings: { ...defaultMappings["financialMappings"] },
          };
        } else {
          desc = {
            basicMappings: { ...defaultMappings["basicMappings"] },
            financialMappings: {},
          };
        }
      }
      // performs sorting for cvr fields in descending order
      // const desc = { basicMappings: {}, financialMappings: {} };
      // custom sorting function by comparing values as first and second inside the filtered array
      Object.entries(
        searchInput.length > 1
          ? searchResults[activeKey]
          : defaultMappings[activeKey]
      )
        .sort((first, second) => (first > second ? -1 : 1))
        .forEach((mapping) => {
          desc[activeKey][mapping[0]] = mapping[1];
        });
      searchInput.length > 1
        ? setSearchResults(desc)
        : setDefaultMappings(desc);
    }
  };

  // This function is responsible for sorting the Pipedrive fields in Mapping window in both ascending and descending order
  const onSortPDFields = () => {
    isCVRSort.current = undefined;
    if (isPDSort.current === undefined) {
      isPDSort.current = true;
    } else {
      isPDSort.current = !isPDSort.current;
    }

    let filteredArray = getFilteredArray(
      searchInput.length > 1
        ? searchResults[activeKey]
        : defaultMappings[activeKey]
    );

    if (isPDSort.current === true) {
      // performs sorting for pd fields in ascending order
      // const asc = { basicMappings: {}, financialMappings: {} };
      let asc;
      if (Object.keys(defaultMappings).length <= 1) {
        asc = { basicMappings: {} };
      } else {
        if (activeKey === "basicMappings") {
          asc = {
            basicMappings: {},
            financialMappings: { ...defaultMappings["financialMappings"] },
          };
        } else {
          asc = {
            basicMappings: { ...defaultMappings["basicMappings"] },
            financialMappings: {},
          };
        }
      }
      // custom sorting function by comparing values as first and second inside the filtered array
      filteredArray
        .sort((first, second) =>
          first.keyword.toLowerCase() > second.keyword.toLowerCase() ? 1 : -1
        )
        .forEach((mapping) => {
          asc[activeKey][mapping.key] =
            searchInput > 1
              ? searchResults[activeKey][mapping.key]
              : defaultMappings[activeKey][mapping.key];
        });
      searchInput.length > 1 ? setSearchResults(asc) : setDefaultMappings(asc);
    } else {
      // performs sorting for pd fields in descending order
      // const desc = { basicMappings: {}, financialMappings: {} };
      let desc;
      if (Object.keys(defaultMappings).length <= 1) {
        desc = { basicMappings: {} };
      } else {
        if (activeKey === "basicMappings") {
          desc = {
            basicMappings: {},
            financialMappings: { ...defaultMappings["financialMappings"] },
          };
        } else {
          desc = {
            basicMappings: { ...defaultMappings["basicMappings"] },
            financialMappings: {},
          };
        }
      }
      // custom sorting function by comparing values as first and second inside the filtered array
      filteredArray
        .sort((first, second) =>
          first.keyword.toLowerCase() > second.keyword.toLowerCase() ? -1 : 1
        )
        .forEach((mapping) => {
          desc[activeKey][mapping.key] =
            searchInput > 1
              ? searchResults[activeKey][mapping.key]
              : defaultMappings[activeKey][mapping.key];
        });
      searchInput.length > 1
        ? setSearchResults(desc)
        : setDefaultMappings(desc);
    }
  };

  // These functions are responsible for showing Success and Error Modals
  const hideSuccessModal = () => setSuccessModal(false);
  const hideErrorModal = () => setErrorModal(false);
  const hideDeleteModal = () => setShowDeleteModal(false);

  // This function is responsible for validating Pipedrive deleted fields
  const validatePipedriveDeletedFields = (defaultMappings) => {
    setDeletedPipedriveFields([]);
    const emptyArray = [];
    const pdFieldsSet = new Set(
      pdProperties.map((pdProperty) => pdProperty.value)
    );
    Object.keys(defaultMappings).forEach((item) => {
      Object.entries(defaultMappings[item]).forEach((mapping) => {
        if (mapping[1].pdValue && !pdFieldsSet.has(mapping[1].pdValue)) {
          emptyArray.push(mapping[1].pdValue);
        }
      });
    });
    setDeletedPipedriveFields(emptyArray);
  };

  const onCheckCVRMapping = (mappedItem) => {
    if (
      (!defaultMappings["basicMappings"]["CVR Number"] ||
        defaultMappings["basicMappings"]["CVR Number"].pdValue === "") &&
      activeKey === "financialMappings"
    ) {
      if (mappedItem[0] === "CVR Number" && mappedItem[1].pdValue === "") {
        const r = { pdLabel: "Select", pdValue: "", type: "string" };
        const index = mappedItem[1].items.findIndex(
          (item) => item.label === r.label || item.label === ""
        );
        if (index === -1) {
          mappedItem[1].items.push(r);
        }
        return mappedItem;
      } else {
        return mappedItem;
      }
    } else {
      if (mappedItem[1].pdValue === "" && mappedItem[0] === "CVR Number") {
        const index = pdProperties.findIndex((item) => {
          return item.label === "Select";
        });
        if (index !== -1) {
          pdProperties[index].label = "";
        }
        setEnableEditing(true);
        return mappedItem;
      } else {
        return mappedItem;
      }
    }
  };

  const changeHeader = (item) => {
    const headerTitle = item.split("Mappings");
    return headerTitle[0].toUpperCase();
  };

  const checkEditing = () => {
    if (activeItem === "1" && isCVRCheck) {
      setEnableEditing(false);
    }
  };

  useEffect(() => {
    filterDefaultMappings();
    checkEditing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappings]);

  useEffect(() => {
    if (
      defaultMappings &&
      Object.keys(defaultMappings).length > 0 &&
      pdProperties &&
      pdProperties.length > 0
    ) {
      validatePipedriveDeletedFields(defaultMappings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultMappings, pdProperties]);

  return (
    <div>
      <DeleteModal
        show={showDeleteModal}
        hideDeleteModal={hideDeleteModal}
        onDeleteExistingMapping={onDeleteExistingMapping}
      />
      <SuccessModal
        show={successModal}
        message={message}
        hideSuccessModal={hideSuccessModal}
      />
      <ErrorModal
        show={errorModal}
        message={message}
        hideErrorModal={hideErrorModal}
      />
      <SaveModal show={showSaveModal} hideSaveModal={hideSaveModal} />
      <div className="search-button-container">
        <div className="mapping-search-bar">
          <span className="my-auto">
            <FaSearch size={15} className="ms-3 me-2 search-icon" />
          </span>
          <input
            type="search"
            placeholder="Search"
            onChange={(e) => onSearchItems(e.target.value)}
            className="form-control mapping-search"
            value={searchInput}
          />
        </div>
        <div className="search-sidebar-container">
          <SideBar
            getPDFields={getPDFields}
            defaultMappings={defaultMappings}
          />
        </div>
      </div>
      <Accordion
        defaultActiveKey={activeItem}
        onSelect={(eventKey) => storeActiveAccordion(eventKey)}
      >
        {defaultMappings &&
          Object.keys(defaultMappings).map((item, index) => (
            <Accordion.Item
              eventKey={index.toString()}
              key={index.toString()}
              className="mt-2"
            >
              <Accordion.Header onClick={() => setActiveKey(item)}>
                {activeItem === index.toString() ? (
                  <FaCaretDown className="me-1" size={12} />
                ) : (
                  <FaCaretRight className="me-1" size={12} />
                )}
                <span className="accordion-title">
                  {changeHeader(item)} DATA
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {Object.keys(searchResults[item]).length === 0 &&
                searchInput.length > 1 ? (
                  <SearchResultsNotFound />
                ) : (
                  <Table responsive className="mapping-table" bordered>
                    <thead className="mapping-table-header">
                      <MappingWindowHeader
                        // Headers for Mapping Window which has sort functionality
                        isCVRSort={isCVRSort.current}
                        isPDSort={isPDSort.current}
                        onSortCVRFields={onSortCVRFields}
                        onSortPDFields={onSortPDFields}
                      />
                    </thead>
                    <tbody className="table-body">
                      {Object.entries(
                        searchInput.length > 1
                          ? searchResults[item]
                          : defaultMappings[item]
                      ).map((mapping) => {
                        return (
                          <tr
                            key={mapping[0]}
                            className={
                              enableEditing && storedKey === mapping[0]
                                ? "add-mapping-container"
                                : "mapped-row"
                            }
                          >
                            {enableEditing && storedKey === mapping[0] ? (
                              // Component responsible for editing mapped rows
                              <EditMapping
                                mappedItem={mapping}
                                mappings={mappings[item]}
                                storedKey={storedKey}
                                onHandleCVRSelect={onHandleCVRSelect}
                                onHandlePDSelect={onHandlePDSelect}
                                editCVRValue={editCVRValue}
                                editPDValue={editPDValue}
                                onCancelMapping={onCancelMapping}
                                onUpdateMapping={onUpdateMapping}
                                disablePipedriveValue={disablePipedriveValue}
                                selectDefaultProperty={selectDefaultProperty}
                                enableEditing={enableEditing}
                                isCVRCheck={isCVRCheck}
                                item={item}
                                pdProperties={pdProperties}
                              />
                            ) : (
                              // Component responsible for showing mapped rows
                              <MappedRows
                                mappedItem={onCheckCVRMapping(mapping)}
                                selectDefaultProperty={selectDefaultProperty}
                                onEditMapping={onEditMapping}
                                deleteExistingMapping={setShowDeleteModal}
                                deleteMappedItem={setDeleteMappedItem}
                                storedKey={storedKey}
                              />
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
      <div className="d-flex justify-content-end">
        {Object.keys(searchResults).length === 0 && searchInput.length > 1 ? (
          ""
        ) : (
          <>
            {deletedPipedriveFields.length > 0 ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip className="tool-tip">
                    Please remap the fields for which custom property has been
                    deleted
                  </Tooltip>
                }
              >
                <Button className="save-mapping-button mt-2 disable-save-mapping-btn">
                  Save mappings
                </Button>
              </OverlayTrigger>
            ) : (
              <Button
                onClick={() =>
                  onSaveMappings({
                    setLoading,
                    mappings,
                    defaultMappings,
                    searchParams,
                    setIsDirty,
                    setMessage,
                    setSuccessModal,
                    setErrorModal,
                  })
                }
                disabled={enableEditing || deletedPipedriveFields.length > 0}
                className="save-mapping-button mt-2"
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Save mappings</span>
                )}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Configurator;

// This function is responsible for saving mappings that are mapped in the Mapping window
export const onSaveMappings = async ({
  setLoading,
  mappings,
  defaultMappings,
  searchParams,
  setIsDirty,
  setMessage,
  setSuccessModal,
  setErrorModal,
}) => {
  try {
    setLoading(true);
    let fields = {};
    if (Object.keys(mappings).length >= 2) {
      fields = { basicMappings: {}, financialMappings: {} };
    } else {
      fields = { basicMappings: {} };
    }
    // Looping over mapped rows in the mapping window and adding them in the fields object if its value is present
    Object.keys(defaultMappings).map((item) => {
      for (const key in defaultMappings[item]) {
        if (
          defaultMappings[item][key].pdValue ||
          defaultMappings[item][key].pdValue !== ""
        ) {
          fields[item][key] = {
            pdLabel: defaultMappings[item][key].pdLabel,
            pdValue: defaultMappings[item][key].pdValue,
          };
        }
      }
      return null;
    });
    // Getting parameters from session storage
    const companyId = searchParams.get("companyId");
    await axios.post(getUrl("REACT_APP_SAVE_MAPPING_URL"), {
      companyId,
      fields,
    });
    setIsDirty(false); // enables the Next button in the Navbar
    setMessage(
      "cmsContent.notifications.mappingWindow.successContent.saveMapping.message"
    );
    setSuccessModal(true);
  } catch (error) {
    console.log(error);
    let errorMessage;
    if (error.response && error.response.data.message)
      errorMessage = error.response.data.message;
    else errorMessage = error.message;
    setMessage(errorMessage);
    setErrorModal(true);
  } finally {
    setLoading(false);
  }
};
