import { createContext, useState, useContext } from "react";

const NavContext = createContext();

// Function responsible for checking context and returning it
export const useDirty = () => {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error("useDirty can only be used inside DirtyProvider");
  }
  return context;
};

export const DirtyProvider = ({ children }) => {
  //const dirtyHook = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [cmsInstance, setCmsInstance] = useState(undefined);
  const [isAllowedUser, setIsAllowedUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const hideErrModal = () => setErrorModal(false);
  const hideSuccModal = () => setSuccessModal(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const hideSaveModal = () => setShowSaveModal(false);
  const [mappings, setMappings] = useState([]);
  const [defaultMappings, setDefaultMapping] = useState({});
  const [deletedPipedriveFields, setDeletedPipedriveFields] = useState([]);
  const [isCVRCheck, setIsCVRCheck] = useState(false);
  const dirtyHook = {
    isDirty,
    setIsDirty,
    cmsInstance,
    setCmsInstance,
    isAllowedUser,
    setIsAllowedUser,
    isEdit,
    setIsEdit,
    allowedUsers,
    setAllowedUsers,
    message,
    setMessage,
    errorModal,
    setErrorModal,
    successModal,
    setSuccessModal,
    hideErrModal,
    hideSuccModal,
    showSaveModal,
    setShowSaveModal,
    mappings,
    setMappings,
    defaultMappings,
    setDefaultMapping,
    hideSaveModal,
    deletedPipedriveFields,
    setDeletedPipedriveFields,
    isCVRCheck,
    setIsCVRCheck,
  };
  return (
    <NavContext.Provider value={dirtyHook}>{children}</NavContext.Provider>
  );
};
