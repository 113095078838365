import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import AppExtensionsSDK from "@pipedrive/app-extensions-sdk";
import { Container, Spinner, Button, ProgressBar } from "react-bootstrap";

import ErrorModal from "../components/ErrorModal";
import { getUrl } from "../helpers/url";

import { CmsConstructor } from "../helpers/cmsConstructor";
import { useDirty } from "../context/dirty";

const BulkUpdate = () => {
  const { setCmsInstance, setMessage, setErrorModal } = useDirty();
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchParams] = useSearchParams();
  const [bulkUpdateData, setBulkUpdateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [updateStats, setUpdateStats] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const intervalId = useRef();
  const timeIntervalId = useRef();
  const currentTime = useRef();
  const [delayTime, setDelayTime] = useState(0);

  // converting the selectedIds and excludedIds in to array
  const getOrgIds = (ids) => {
    let parsedIds;
    if (ids.includes(",")) {
      parsedIds = ids.split(",").map((eachId) => parseInt(eachId));
    } else {
      parsedIds = [parseInt(ids)];
    }
    return parsedIds;
  };

  // Filtering organizations based on CVR Number
  const filterOrganizations = (selectedOrgs, cvrNumberKey) => {
    const filteredOrganizations = [];
    selectedOrgs.forEach((org) => {
      if (org[cvrNumberKey]) {
        filteredOrganizations.push({
          id: org.id,
          [cvrNumberKey]: org[cvrNumberKey],
          name: org.name,
        });
      }
    });
    return filteredOrganizations;
  };

  // store data in state
  const storeData = (selectedOrgs, filteredOrgs, cvrNumberKey) => {
    setBulkUpdateData({
      selectedOrgs,
      filteredOrgs,
      cvrNumberKey,
    });
  };

  // This function is responsible for getting the organizations selected by the user
  const getSelectedOrganizations = async () => {
    try {
      // Get query parameters
      const companyId = searchParams.get("companyId");
      const userId = searchParams.get("userId");
      let selectedIds = searchParams.get("selectedIds");
      let excludedIds = searchParams.get("excludedIds");
      const filter = JSON.parse(searchParams.get("filter"));

      // convert string of ids to array
      selectedIds = selectedIds ? getOrgIds(selectedIds) : null;
      excludedIds = excludedIds ? getOrgIds(excludedIds) : null;

      let organizationsData = [];

      // Fetch Mappings
      const { data: mappings } = await axios.get(
        getUrl("REACT_APP_GET_MAPPING_URL"),
        {
          params: { companyId, userId },
        }
      );

      const cvrNumberKey = mappings?.basicMappings["CVR Number"]?.pdValue;
      if (
        !cvrNumberKey ||
        cvrNumberKey === undefined ||
        cvrNumberKey === "undefined"
      )
        throw new Error(
          "cmsContent.notifications.bulkUpdate.failContent.cvrNumberNotMapped.message"
        );

      // parameters required for fetching PD organizations
      let start = 0;
      let limit = 500;

      const orgUserId = filter?.user_id ? filter.user_id : "";
      const filterId = filter?.filter_id ? filter.filter_id : "";
      const firstChar = filter?.first_char ? filter.first_char : "";

      // Fetch organizations
      let { data: organizations } = await axios.get(
        getUrl("REACT_APP_FETCH_ALL_ORGANIZATIONS"),
        {
          params: {
            companyId,
            userId,
            start,
            limit,
            filterId,
            orgUserId,
            firstChar,
          },
        }
      );

      organizationsData.push(...organizations.data);
      // Getting total count of organizations
      const totalOrgsCount =
        organizations["additional_data"].summary["total_count"];
      // Calculate number of iterations required to fetch all organizations
      const numberOfIterations =
        Math.floor(Math.ceil(totalOrgsCount / limit)) - 1;

      if (numberOfIterations > 0) {
        start = organizations["additional_data"].pagination["next_start"];
        // Iterating to get all the organizations
        for (let i = 0; i < numberOfIterations; i++) {
          // Fetch Organizations
          let { data: organizations } = await axios.get(
            getUrl("REACT_APP_FETCH_ALL_ORGANIZATIONS"),
            {
              params: {
                companyId,
                userId,
                start,
                limit,
                filterId,
                orgUserId,
                firstChar,
              },
            }
          );

          organizationsData.push(...organizations.data);

          if (organizations["additional_data"].pagination["next_start"])
            start = organizations["additional_data"].pagination["next_start"];
        }
      }
      // resetting values to 0
      start = 0;

      // Filtering organizations that are having CVR Number based on users selected orgs
      if (!selectedIds && !excludedIds) {
        const filteredOrgs = filterOrganizations(
          organizationsData,
          cvrNumberKey
        );

        storeData(organizationsData, filteredOrgs, cvrNumberKey);
      } else if (!selectedIds && excludedIds) {
        const selectedOrgs = organizationsData.filter(
          (org) => !excludedIds.includes(org.id)
        );
        const filteredOrgs = filterOrganizations(selectedOrgs, cvrNumberKey);

        storeData(selectedOrgs, filteredOrgs, cvrNumberKey);
      } else if (selectedIds && !excludedIds) {
        const selectedOrgs = organizationsData.filter((org) =>
          selectedIds.includes(org.id)
        );

        const filteredOrgs = filterOrganizations(selectedOrgs, cvrNumberKey);

        storeData(selectedOrgs, filteredOrgs, cvrNumberKey);
      } else if (selectedIds && excludedIds) {
        const selectedOrgs = organizationsData.filter(
          (org) => selectedIds.includes(org.id) && !excludedIds.includes(org.id)
        );

        const filteredOrgs = filterOrganizations(selectedOrgs, cvrNumberKey);

        storeData(selectedOrgs, filteredOrgs, cvrNumberKey);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch subscription details of user
  const fetchSubscriptionDetails = async (companyId) => {
    const subscriptionData = await axios.get(
      getUrl("REACT_APP_GET_PLAN_DATA_URL"),
      { params: { companyId } }
    );
    return subscriptionData;
  };

  // This function is responsible for initiating bulk update
  const onBulkUpdate = async () => {
    try {
      setIsUpdating(true);
      const companyId = searchParams.get("companyId");
      const userId = searchParams.get("userId");

      const { selectedOrgs, filteredOrgs, cvrNumberKey } = bulkUpdateData;

      const { data: subscription } = await fetchSubscriptionDetails(companyId);

      if (subscription.usageCount >= 15 && subscription.plan === "basic")
        throw new Error(
          "cmsContent.notifications.bulkUpdate.failContent.trialExpired.message",
          402
        );

      if (subscription.plan === "premium-cancelled")
        throw new Error(
          "cmsContent.notifications.bulkUpdate.failContent.premiumCancelled.message"
        );

      if (
        subscription.plan === "basic" &&
        selectedOrgs.length > 15 - subscription.usageCount
      )
        throw new Error(
          "cmsContent.notifications.bulkUpdate.failContent.noSufficientCredits.message"
        );

      await axios.post(getUrl("REACT_APP_BULK_UPDATE"), {
        companyId,
        userId,
        cvrNumberKey,
        filteredOrgs,
        totalSelectedOrgsCount: selectedOrgs.length,
      });
      setShowProgressBar(true);
      await getTime();
      settingIntervals();
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShow(true);
    } finally {
      setIsUpdating(false);
    }
  };

  // This function is responsible for setting interval for fetching stats
  const settingIntervals = () => {
    intervalId.current = setInterval(async () => {
      const stats = await getBulkUpdateStats();
      const { failedRecords, processedRecords, pushedRecords, startTime } =
        stats;
      setUpdateStats(stats);
      if (!timeIntervalId.current) {
        intervalToGetTime();
      }
      const delayInMins = updationDelay(startTime, currentTime.current);
      setDelayTime(delayInMins);
      if (
        // eslint-disable-next-line eqeqeq
        pushedRecords == processedRecords + failedRecords ||
        delayInMins >= delayTimeCalculation(pushedRecords)
      ) {
        clearInterval(intervalId.current);
        intervalId.current = null;
        clearInterval(timeIntervalId.current);
        timeIntervalId.current = null;
      }
    }, 3000);
  };

  // This function is responsible for getting stats
  const getBulkUpdateStats = async () => {
    const companyId = searchParams.get("companyId");
    const { data: stats } = await axios.get(
      getUrl("REACT_APP_GET_BULK_UPDATE_STATS"),
      {
        params: {
          companyId,
        },
      }
    );
    return stats;
  };

  // This function is responsible for showing Error Modal
  const hideErrorModal = () => setShow(false);

  // This function is responsible for initializing pipedrive sdk while opening it from iframe
  const initalizePipedriveSDK = async (id) => {
    try {
      await new AppExtensionsSDK({ identifier: id }).initialize({
        size: { width: 434, height: 500 },
      });
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShow(true);
    }
  };
  // This function is responsible for calculating delay in minutes
  const updationDelay = (startTime, currentTime) => {
    const currentDate = new Date(currentTime);
    const storedDate = new Date(startTime);
    let delayMin = (currentDate.getTime() - storedDate.getTime()) / 1000;
    delayMin = delayMin / 60;
    return Math.abs(Math.round(delayMin));
  };

  // This function is responsible for fetching bulk update stats and rendering the components
  const checkStatsAndProcessed = async () => {
    try {
      setLoading(true);
      const stats = await getBulkUpdateStats();

      if (stats && Object.keys(stats).length === 0) {
        await getSelectedOrganizations();
      } else {
        const { failedRecords, processedRecords, pushedRecords, startTime } =
          stats;
        await getTime();
        intervalToGetTime();
        const delayInMins = updationDelay(startTime, currentTime.current);
        if (
          // eslint-disable-next-line eqeqeq
          pushedRecords == failedRecords + processedRecords ||
          delayInMins >= delayTimeCalculation(pushedRecords)
        ) {
          clearInterval(timeIntervalId.current);
          timeIntervalId.current = null;
          await getSelectedOrganizations();
        } else {
          setUpdateStats(stats);
          setShowProgressBar(true);
          settingIntervals();
        }
      }
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShow(true);
    } finally {
      setLoading(false);
    }
  };

  // This function is responsible for calculating the delay time based on pushed records
  const delayTimeCalculation = (records) => {
    const calculatedDelay = Math.ceil((2 * records) / 20);
    return calculatedDelay;
  };

  // This function is responsible for fetching current time for third party API at regular intervals
  const intervalToGetTime = () => {
    timeIntervalId.current = setInterval(async () => {
      await getTime();
    }, 60000);
  };

  // This function is responsible for getting time for third party API
  const getTime = async () => {
    const { data: timeData } = await axios.get(
      `${getUrl("REACT_APP_GET_CURRENT_TIME")}?timeZone=Europe/Copenhagen`
    );
    currentTime.current = timeData.dateTime;
  };

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      initalizePipedriveSDK(id);
    }
    checkStatsAndProcessed();
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      if (timeIntervalId.current) {
        clearInterval(timeIntervalId.current);
      }
      intervalId.current = null;
      timeIntervalId.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor({ setMessage, setErrorModal });
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bulk-update-page">
      <ErrorModal
        show={show}
        message={errorMsg}
        hideErrorModal={hideErrorModal}
      />
      <Container className="my-3">
        {loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center loading-container">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Spinner
                animation="border"
                variant="success"
                role="status"
              ></Spinner>
              <p className="bulk-update-heading mt-3">
                Fetching organizations list, please wait...
              </p>
            </div>
          </div>
        ) : (
          <div className="bulk-update-orgs-list">
            {showProgressBar && (
              <div>
                {progressBar(updateStats, delayTime, delayTimeCalculation)}
              </div>
            )}
            {!showProgressBar &&
              bulkUpdateData &&
              Object.keys(bulkUpdateData).length > 0 && (
                <div>{oragnizationsList(bulkUpdateData)}</div>
              )}
          </div>
        )}
      </Container>
      {!loading && !showProgressBar && bulkUpdateData && (
        <div className="bulk-update-bottom-container">
          <div>
            <Button
              className="bulk-update-button me-3"
              onClick={() => onBulkUpdate()}
              disabled={
                (Object.keys(bulkUpdateData).length > 0 &&
                  bulkUpdateData?.filteredOrgs.length === 0) ||
                isUpdating
              }
            >
              {isUpdating ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Update</span>
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
// Organizations List component
const oragnizationsList = (bulkUpdateData) => {
  return (
    <div>
      <p className="bulk-update-para">
        {bulkUpdateData.filteredOrgs.length} out of{" "}
        {bulkUpdateData.selectedOrgs.length} selected Organizations has CVR
        Number.
      </p>
      <h2 className="bulk-update-heading">Organization List</h2>
      <div>
        {bulkUpdateData.filteredOrgs &&
          bulkUpdateData.filteredOrgs.map((org) => (
            <div key={org.id}>
              <span className="bulk-update-org-name ms-2">{org.name}</span>
            </div>
          ))}
        {bulkUpdateData.filteredOrgs &&
          bulkUpdateData.filteredOrgs.length === 0 && (
            <p className="bulk-update-org-name ms-2">
              Selected Organizations doesn't have a CVR Number.
            </p>
          )}
      </div>
    </div>
  );
};
// Progress Bar component
const progressBar = (updateStats, delayTime, delayTimeCalculation) => {
  const { pushedRecords, processedRecords, failedRecords, totalRecords } =
    updateStats;
  return (
    <div>
      {updateStats && Object.keys(updateStats).length > 0 ? (
        <div>
          <p className="bulk-updating-text">
            {
              // eslint-disable-next-line eqeqeq
              pushedRecords == processedRecords + failedRecords ||
              delayTime >= delayTimeCalculation(pushedRecords) ? (
                <span>Update completed</span>
              ) : (
                <span>
                  Updating...({failedRecords + processedRecords}/{pushedRecords}
                  )
                </span>
              )
            }
          </p>
          <ProgressBar
            variant="success"
            min={0}
            max={pushedRecords}
            now={failedRecords + processedRecords}
            className="credits-progress-bar my-3"
          />
          <p className="bulk-update-para">
            Out of {totalRecords} Organizations, {pushedRecords} Organizations
            are pushed for processing.
          </p>
          <p className="bulk-update-stats">
            <b>Organizations successfully updated: </b>
            {processedRecords} <br /> <b>Organizations failed to update: </b>{" "}
            {failedRecords}
          </p>
          <p className="bulk-update-stats">
            <b>Note: </b>
            <span>
              The process will be completed in the background, so you can close
              the popup window and can come back to view the progress.
            </span>
          </p>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center loading-container">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Spinner
              animation="border"
              variant="success"
              role="status"
            ></Spinner>
            <p className="bulk-update-heading mt-3">update in progress...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkUpdate;
