import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import AddCompanyInfo from "../components/AddCompanyInfo";
import SetupGuideHeader from "../components/SetupGuideHeader";

import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";
import { useDirty } from "../context/dirty";
import SuccessModal from "../components/SuccessModal";

import { Spinner } from "react-bootstrap";

function SetupGuide() {
  const {
    cmsInstance,
    successModal,
    message,
    hideSuccModal,
    setCmsInstance,
    setMessage,
    setErrorModal,
  } = useDirty();

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor({ setMessage, setErrorModal });
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SuccessModal
        show={successModal}
        message={message}
        hideSuccessModal={hideSuccModal}
      />
      <div className="setup-guide-page">
        <SetupGuideHeader />
        {!cmsInstance ? (
          <div
            className="d-flex flex-row justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <Spinner animation="border" variant="success" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="d-flex flex-column align-items-center">
              <CmsEditAndSave />
            </div>
            <Container className="p-5">
              <div className="setup-guide-main-content d-flex flex-column align-items-center justify-content-center">
                <div className="col-md-11 col-12">
                  <div className="setup-guide-content-container">
                    {cmsInstance &&
                      cmsInstance.showRichText(
                        "cmsContent.setupGuide",
                        "setup-guide"
                      )}
                    <AddCompanyInfo />

                    <div>
                      <p className="setup-guide-footer-text">
                        Updated 20/ 01/ 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </>
        )}
      </div>
    </>
  );
}

export default SetupGuide;
