import React from "react";
import { Col, Row, Spinner, Container } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import AppExtensionsSDK from "@pipedrive/app-extensions-sdk";

import Configurator from "../components/Configurator";
import ErrorModal from "../components/ErrorModal";
import SuccessModal from "../components/SuccessModal";

import { getUrl } from "../helpers/url";

import { useDirty } from "../context/dirty";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

function MappingWindow() {
  const {
    cmsInstance,
    setCmsInstance,
    setMessage,
    setErrorModal,
    successModal,
    hideSuccModal,
    message,
    mappings,
    setMappings,
    errorModal,
  } = useDirty();

  //const [mappings, setMappings] = useState({});
  const [pdProperties, setPDProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  // const [show, setShow] = useState(false);
  // const [errorMsg, setErrorMsg] = useState("");
  const effectRan = useRef(false);

  // This function is used for getting the Pipedrive fields
  const getPDFields = async () => {
    try {
      const companyId = searchParams.get("companyId");
      const userId = searchParams.get("userId");
      const { data: pdFields } = await axios.get(
        getUrl("REACT_APP_GET_PD_FIELDS"),
        { params: { companyId, userId } }
      );

      setPDProperties(pdFields);
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setMessage(message);
      setErrorModal(true);
    }
  };

  // This function is responsible for showing Error Modal
  const hideErrorModal = () => setErrorModal(false);

  // This function is responsible for initializing pipedrive sdk while opening it from iframe
  const initalizePipedriveSDK = async (id) => {
    try {
      await new AppExtensionsSDK({ identifier: id }).initialize({
        size: { width: 1200, height: 700 },
      });
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setMessage(message);
      setErrorModal(true);
    }
  };

  useEffect(() => {
    // Prevented useEffect firing twice in development by using the below condition
    if (effectRan.current === false) {
      const id = searchParams.get("id");
      if (id) {
        initalizePipedriveSDK(id);
      }
      fetchMappings({
        searchParams,
        setMappings,
        setLoading,
        setMessage,
        setErrorModal,
      });
      getPDFields();
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/mappingwindow") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor({ setMessage, setErrorModal });
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorModal
        show={errorModal}
        message={message}
        hideErrorModal={hideErrorModal}
      />

      <SuccessModal
        show={successModal}
        message={message}
        hideSuccessModal={hideSuccModal}
      />

      <div className="mapping-window-bg-container">
        {window.location.pathname === "/mappingwindow" && (
          <div className="d-flex flex-column align-items-center">
            <CmsEditAndSave />
          </div>
        )}
        <Container className="mapping-window-container px-4 my-5">
          <Row>
            <Col>
              <div className="my-5">
                {cmsInstance &&
                  cmsInstance.showRichText(
                    "cmsContent.mappingWindow",
                    "mapping-window"
                  )}
                {loading ? (
                  <div className="d-flex flex-column justify-content-center align-items-center loading-container">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <Spinner
                        animation="border"
                        variant="success"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  </div>
                ) : (
                  <Configurator
                    mappings={mappings}
                    getPDFields={getPDFields}
                    pdProperties={pdProperties}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default MappingWindow;

// This function is responsible for fetching mappings
export const fetchMappings = async ({
  searchParams,
  setMappings,
  setLoading,
  setMessage,
  setErrorModal,
}) => {
  try {
    // Getting query params by using useSearchParams
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");

    let { data: mappings } = await axios.get(
      getUrl("REACT_APP_GET_MAPPING_URL"),
      {
        params: { companyId, userId },
      }
    );

    setMappings(mappings);
    setLoading(false);
  } catch (error) {
    console.log(error);
    let message;
    if (error.response && error.response.data.message)
      message = error.response.data.message;
    else message = error.message;
    setMessage(message);
    setErrorModal(true);
  }
};
