import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import "./assets/style.css";
import SearchWindow from "./pages/SearchWindow";
import MappingWindow from "./pages/MappingWindow";
import Subscription from "./pages/Subscription";
import NavBarPage from "./pages/NavBarPage";
import SetupGuide from "./pages/SetupGuide";
import IframeWindow from "./pages/IframeWindow";
import BulkUpdate from "./pages/BulkUpdate";
import CmsNotifications from "./helpers/CmsNotifications";
import { DirtyProvider } from "./context/dirty";

function App() {
  return (
    <DirtyProvider>
      <Router>
        <Routes>
          <Route path="/setup" element={<NavBarPage />}></Route>
          <Route path="/mappingwindow" element={<MappingWindow />}></Route>
          <Route path="/iframewindow" element={<IframeWindow />}></Route>
          <Route path="/subscription" element={<Subscription />}></Route>
          <Route path="/searchwindow" element={<SearchWindow />}></Route>
          <Route path="/setupguide" element={<SetupGuide />}></Route>
          <Route path="/bulkupdate" element={<BulkUpdate />}></Route>
          <Route
            path="/edit-notifications"
            element={<CmsNotifications />}
          ></Route>
        </Routes>
      </Router>
    </DirtyProvider>
  );
}

export default App;
