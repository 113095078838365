import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { VscEdit } from "react-icons/vsc";
import { RiDeleteBin6Line } from "react-icons/ri";

function MappedRows({
  mappedItem,
  selectDefaultProperty,
  onEditMapping,
  //onDeleteExistingMapping,
  deleteExistingMapping,
  deleteMappedItem,
  storedKey,
}) {
  return (
    <>
      <td className="cvr-name-container ps-4">
        <span className="my-auto cvr-label">{mappedItem[0]}</span> <br />
        <span className="my-auto cvr-description">
          {mappedItem[1].cvrFieldDescription}
        </span>
      </td>
      <td>
        <p
          className={
            selectDefaultProperty(mappedItem[1]).pdLabel ===
            "pipedrive custom property deleted"
              ? "pd-deleted-label my-auto ms-3 "
              : "pd-label my-auto ms-3 "
          }
        >
          {selectDefaultProperty(mappedItem[1]).pdLabel === "" ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ fontSize: "12px" }}>
                  To add mapping for this field click on Action-edit or leave as
                  it is to not map this field.
                </Tooltip>
              }
            >
              <span style={{ cursor: "pointer", color: "#7a91ab" }}>
                Not Mapped
              </span>
            </OverlayTrigger>
          ) : (
            selectDefaultProperty(mappedItem[1]).pdLabel
          )}
        </p>
      </td>
      <td className="d-flex flex-row justify-content-center actions-column">
        <div className="mt-2">
          <button
            className="actions-btn"
            onClick={() => onEditMapping(mappedItem[0])}
            disabled={storedKey ? storedKey !== mappedItem[0] : ""}
          >
            <VscEdit size={16} />
          </button>
          <button
            className="actions-btn"
            onClick={() => {
              deleteExistingMapping(true);
              deleteMappedItem(mappedItem[0]);
            }}
            disabled={
              mappedItem[1]?.required === true ||
              (storedKey ? storedKey !== mappedItem[0] : "") ||
              mappedItem[1]?.pdValue === ""
            }
          >
            <RiDeleteBin6Line size={16} />
          </button>
        </div>
      </td>
    </>
  );
}

export default MappedRows;
