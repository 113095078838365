import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import AddCompanyInfo from "../components/AddCompanyInfo";

function Confirmation() {
  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <div className="confirmation-page-box my-5">
              <AddCompanyInfo />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Confirmation;
