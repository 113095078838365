import React from "react";

function SearchResultsNotFound() {
  return (
    <div className="search-results-container">
      <h3 className="search-button-container-heading">No Mappings Found</h3>
      <p className="search-button-container-para">Try changing your filters</p>
    </div>
  );
}

export default SearchResultsNotFound;
