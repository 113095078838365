import React from "react";
import { Row, Col, Container, Spinner, Button } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import AppExtensionsSDK from "@pipedrive/app-extensions-sdk";
import axios from "axios";
import { AiFillCreditCard } from "react-icons/ai";
import { HiOutlineRefresh } from "react-icons/hi";

import SearchWindowResults from "../components/SearchWindowResults";
import ErrorModal from "../components/ErrorModal";
import FinancialDataTable from "../components/FinancialDataTable";
import SuccessModal from "../components/SuccessModal";
import { getUrl } from "../helpers/url";

import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";
import { useDirty } from "../context/dirty";

function SearchWindow() {
  const {
    cmsInstance,
    setCmsInstance,
    setMessage,
    setErrorModal,
    message,
    successModal,
    hideSuccModal,
  } = useDirty();
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState({});
  const effectRan = useRef(false);
  const [financialData, setFinancialData] = useState({});
  const [isFinancialDataLoading, setIsFinancialDataLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // This function is responsible for initializing pipedrive sdk while opening it from iframe
  const initalizePipedriveSDK = async (id) => {
    try {
      await new AppExtensionsSDK({ identifier: id }).initialize({
        size: { height: 350 },
      });
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShow(true);
    }
  };

  // This function is responsible getting plan name and searches left above the search bar
  const getPlanInfo = async () => {
    try {
      setLoading(true);
      const companyId = searchParams.get("companyId");
      let { data: planData } = await axios.get(
        getUrl("REACT_APP_GET_PLAN_DATA_URL"),
        {
          params: {
            companyId: companyId,
          },
        }
      );
      // changing the plan name that to be displayed when user breached the limit
      if (planData.plan.includes("-")) {
        planData.plan = `${planData.plan.replace("-", " ")}`;
      }
      setPlanData(planData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShow(true);
    }
  };

  // This function is responsible for showing Error Modal
  const hideErrorModal = () => setShow(false);
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
    getPlanInfo();
    getFinancialData();
  };

  // This function is responsible for getting Financial Data
  const getFinancialData = async () => {
    try {
      setIsFinancialDataLoading(true);
      const companyId = searchParams.get("companyId");
      const userId = searchParams.get("userId");
      const orgId = searchParams.get("selectedIds");

      //Get financial data
      const { data: financialData } = await axios.get(
        getUrl("REACT_APP_GET_FINANCIAL_DATA"),
        {
          params: {
            companyId,
            userId,
            orgId,
          },
        }
      );

      const financial = financialData.financial;

      if (financial && Object.keys(financial).length > 0) {
        const years = Object.keys(financial).sort().reverse();
        let data = {};
        for (let year of years) {
          data[`${year}`] = {
            "Net turnover": financial[`${year}`].revenue
              ? convertNumbers(financial[`${year}`].revenue)
              : "-",
            "Gross profit": financial[`${year}`].grossProfit
              ? convertNumbers(financial[`${year}`].grossProfit)
              : "-",
            "Net result": financial[`${year}`].profitLoss
              ? convertNumbers(financial[`${year}`].profitLoss)
              : "-",
            Equity: financial[`${year}`].equity
              ? convertNumbers(financial[`${year}`].equity)
              : "-",
            "Balance sheet": financial[`${year}`].status
              ? convertNumbers(financial[`${year}`].status)
              : "-",
            "Currency code": financial[`${year}`].currency
              ? financial[`${year}`].currency
              : "-",
          };
        }

        setFinancialData(data);
      } else {
        setFinancialData({});
      }
    } catch (error) {
      console.log(error);
      setFinancialData({});
    } finally {
      setIsFinancialDataLoading(false);
    }
  };

  // This function is responsible for Refreshing CVR Data in PD org
  const onRefreshCVRData = async () => {
    try {
      setIsRefresh(true);
      const companyId = searchParams.get("companyId");
      const userId = searchParams.get("userId");
      const orgId = searchParams.get("selectedIds");

      await axios.get(getUrl("REACT_APP_REFRESH_CVR_DATA"), {
        params: {
          companyId,
          userId,
          orgId,
        },
      });
      setSuccessMessage(
        "cmsContent.notifications.searchWindow.successContent.updateBtn.message"
      );
      setShowSuccessModal(true);
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.details.message) {
        message = error.response.data.details.message;
      } else if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShow(true);
    } finally {
      setIsRefresh(false);
    }
  };

  // This function is responsible for converting number to thousands
  const convertNumbers = (value) => {
    const roundOffNumber = Math.round(parseInt(value) / 1000);
    return roundOffNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  useEffect(() => {
    // Prevented useEffect firing twice in development by using the below condition
    if (effectRan.current === false) {
      const id = searchParams.get("id");
      if (id) {
        initalizePipedriveSDK(id);
      }
      getPlanInfo();
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor({ setMessage, setErrorModal });
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFinancialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        message={successMessage}
        hideSuccessModal={hideSuccessModal}
      />
      <SuccessModal
        message={message}
        show={successModal}
        hideSuccessModal={hideSuccModal}
      />
      <ErrorModal
        show={show}
        message={errorMsg}
        hideErrorModal={hideErrorModal}
      />
      <div className="search-window">
        <div className="d-flex flex-column align-items-center">
          <CmsEditAndSave />
        </div>
        <Container>
          <Col xs={12}>
            <Row className="d-flex flex-row justify-content-center">
              <Col
                xs={12}
                md={10}
                className="search-window-colored-container my-md-5"
              >
                {loading ? (
                  <div className="d-flex flex-column justify-content-center align-items-center loading-container">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <Spinner
                        animation="border"
                        variant="success"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  </div>
                ) : (
                  <div className="my-4 my-sm-5">
                    {planData &&
                      (planData.plan === "premium" ||
                        (planData.plan === "basic" &&
                          planData.usageCount < 15)) && (
                        <div className="d-flex flex-row justify-content-center">
                          <Col xs={12} lg={6}>
                            <FinancialDataTable
                              financialData={financialData}
                              isLoading={isFinancialDataLoading}
                            />
                          </Col>
                        </div>
                      )}
                    <div>
                      {cmsInstance &&
                        cmsInstance.showRichText(
                          "cmsContent.searchWindow",
                          "search-window-content"
                        )}
                      {planData &&
                        (planData.plan === "premium cancelled" ||
                          (planData.plan === "basic" &&
                            planData.usageCount >= 15)) && (
                          <p className="search-window-para my-0">
                            <b>Please note: </b>The financial data table is only
                            visible to the users in paid plan.
                          </p>
                        )}
                      <div className="search-window-plan my-3 my-md-5">
                        <p className="me-3">
                          <span className="card-icon-container">
                            <AiFillCreditCard size={15} />
                          </span>
                          Plan:{" "}
                          <span
                            className={
                              "search-window-plan-bold " +
                              (planData.plan === "premium cancelled"
                                ? "search-window-plan-bold-color "
                                : "")
                            }
                          >
                            {planData.plan}
                          </span>
                        </p>
                        <p>
                          Usage Count:{" "}
                          <span
                            className={
                              "search-window-plan-bold " +
                              (planData.plan === "basic" &&
                              planData.usageCount >= 15
                                ? "search-window-plan-bold-color "
                                : "")
                            }
                          >
                            {planData.usageCount}
                          </span>
                        </p>
                        <p className="ms-auto ms-md-2">
                          <Button
                            variant="success"
                            className="cvr-refresh-button"
                            onClick={() => onRefreshCVRData()}
                          >
                            {isRefresh ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              <span>
                                <HiOutlineRefresh size={12} className="me-1" />
                                Update CVR data
                              </span>
                            )}
                          </Button>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <Col xs={12} lg={6}>
                        <SearchWindowResults
                          getPlanInfo={getPlanInfo}
                          getFinancialData={getFinancialData}
                        />
                      </Col>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default SearchWindow;
