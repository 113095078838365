import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import { getStripeUrl, getUrl } from "../helpers/url";

import { useDirty } from "../context/dirty";

function PostpaidBillingCards({
  currentPlanDetails,
  setCurrentPlanDetails,
  fetchPlanData,
  setShowErrorModal,
  setShowSuccessModal,
  setErrorMessage,
  setSuccessMessage,
}) {
  const { cmsInstance } = useDirty();

  const pricingCardsData = [
    {
      id: 1,
      name: "Free Trial",
      text: "All the basics for business that are just getting started.",
      price: "0 DKK",
      path: "card1",
      points: [
        "Basic and financial data",
        "Customise mappings and Bulk update",
        "Search companies based on name and CVR number",
      ],
    },
    {
      id: 2,
      name: "Premium",
      text: "Premium plan for the businesses that have a high search volume.",
      price: "20 DKK",
      path: "card2",
      points: [
        "1 DKK/ sync",
        "Basic and financial data",
        "Customise mappings and Bulk update",
        "Search companies based on name and CVR number",
      ],
    },
  ];
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const companyId = searchParams.get("companyId");
  const userId = searchParams.get("userId");

  // This function is responsible for rendering buttons based on the plan selected
  const renderButton = (card) => {
    if (currentPlanDetails.plan === "basic" && card.name === "Free Trial") {
      return (
        <Button className="pricing-card-button-disable my-3">
          {currentPlanDetails.usageCount >= 15 ? "Trial Expired" : "Selected"}
        </Button>
      );
    } else if (currentPlanDetails.plan === "basic" && card.name === "Premium") {
      return (
        <Button
          className="pricing-card-button my-3"
          disabled={upgradeLoading}
          onClick={onUpgradePlan}
        >
          {upgradeLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Upgrade</span>
          )}
        </Button>
      );
    } else if (
      currentPlanDetails.plan === "premium" &&
      card.name === "Premium"
    ) {
      return (
        <>
          <Button className="pricing-card-button-disable mt-3">Selected</Button>
          <Button
            className="pricing-card-cancel-button mt-2 mb-3"
            disabled={cancelLoading}
            onClick={onCancelSubscription}
          >
            {cancelLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Cancel Subscription</span>
            )}
          </Button>
        </>
      );
    } else if (
      currentPlanDetails.plan === "premium-cancelled" &&
      card.name === "Premium"
    ) {
      return (
        <Button
          className="pricing-card-button my-3"
          disabled={upgradeLoading}
          onClick={onUpgradePlan}
        >
          {upgradeLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Upgrade</span>
          )}
        </Button>
      );
    }
  };

  // This function is responsible for upgrading the Subscription
  const onUpgradePlan = async () => {
    try {
      setUpgradeLoading(true);

      const { data: customer } = await axios.post(
        getStripeUrl("REACT_APP_FIND_CREATE_CUSTOMER"),
        {
          appSlug: "pipedriveCvrLookup",
          metaData: {
            pipedriveUserId: userId,
          },
        }
      );

      // Create Checkout
      const { data: res } = await axios.post(
        getStripeUrl("REACT_APP_STRIPE_CHECKOUT"),
        {
          customerKey: customer.id,
          appSlug: "pipedriveCvrLookup",
          plan: "premium",
          successUrl: `${
            window.location.href.split("?")[0]
          }?companyId=${companyId}&userId=${userId}&paymentStatus=cardAdded`,
          cancelUrl: `${
            window.location.href.split("?")[0]
          }?companyId=${companyId}&userId=${userId}&paymentStatus=failed`,
          subscriptionData: {
            description: `Subscription active for the company ${companyId}`,
            metaData: {
              companyId,
            },
          },
        }
      );

      if (res.url) {
        // redirects to the stripe window
        window.open(res.url, "_self");
      }
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    } finally {
      setUpgradeLoading(false);
    }
  };

  // This function is used for cancelling the subscription
  const onCancelSubscription = async () => {
    try {
      setCancelLoading(true);
      await axios.post(getUrl("REACT_APP_CANCEL_SUBSCRIPTION"), {
        companyId,
        userId,
      });
      fetchPlanData();
      setSuccessMessage(
        "cmsContent.notifications.subscriptionPage.successContent.cancelSubscription.message"
      );
      setShowSuccessModal(true);
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    } finally {
      setCancelLoading(false);
    }
  };

  // This function is responsible for checking payment status of user based on paymentStatus present in the query parameter
  const paymentStatusCheck = async () => {
    const paymentStatus = searchParams.get("paymentStatus");
    if (paymentStatus === "cardAdded") {
      setCurrentPlanDetails({
        ...currentPlanDetails,
        plan: "premium",
        usageCount: 0,
      });
      navigate(
        `..${window.location.pathname}?companyId=${companyId}&userId=${userId}&paymentStatus=none`
      );
      setSuccessMessage(
        "cmsContent.notifications.subscriptionPage.successContent.upgradePlan.message"
      );
      setShowSuccessModal(true);
    } else if (paymentStatus === "failed") {
      setErrorMessage(
        "cmsContent.notifications.subscriptionPage.failContent.paymentFailed.message"
      );
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (cmsInstance) paymentStatusCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row
      className={`h-100 pricing-cards-container mx-0 ${
        (currentPlanDetails.plan === "premium" ||
          currentPlanDetails.plan === "premium-cancelled") &&
        "d-flex flex-row justify-content-center"
      }`}
    >
      {pricingCardsData &&
        pricingCardsData.map((card) => (
          <Col
            sm={8}
            md={5}
            key={card.id}
            className={`pricing-card mt-3 ${
              (currentPlanDetails.plan === "premium" ||
                currentPlanDetails.plan === "premium-cancelled") &&
              card.name === "Free Trial" &&
              "d-none"
            }`}
          >
            {cmsInstance &&
              cmsInstance.showRichText(
                `cmsContent.subscription.${card.path}.plan`,
                "card-price"
              )}
            <div>{renderButton(card)}</div>
            {cmsInstance &&
              cmsInstance.showRichText(
                `cmsContent.subscription.${card.path}.points`,
                "card-price-points"
              )}
          </Col>
        ))}
    </Row>
  );
}

export default PostpaidBillingCards;
