import React from "react";
import { useState, useRef } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

import { getUrl } from "../helpers/url";

import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

function SearchWindowResults({ getPlanInfo, getFinancialData }) {
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [storedId, setStoredId] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const organizationsData = useRef([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [searchParams] = useSearchParams();

  // This function is responsible for adding organization
  const onAddOrganization = async (organization) => {
    try {
      setStoredId(organization["_id"]);
      setButtonLoading(true);
      // Getting query parameters by using useSearchParams
      const companyId = searchParams.get("companyId");
      const userId = searchParams.get("userId");
      const orgId = searchParams.get("selectedIds");

      const body = {
        userId,
        companyId,
        orgId,
        cvrNo: organization["_source"]?.Vrvirksomhed?.cvrNummer,
      };
      await axios.post(getUrl("REACT_APP_UPDATE_ORGANIZATION_URL"), body);

      setSuccessMessage(
        "cmsContent.notifications.searchWindow.successContent.updateOrganization.message"
      );
      setShowSuccessModal(true);
      setSearchInput("");
      organizationsData.current = [];
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.details.message) {
        errorMessage = error.response.data.details.message;
      } else if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      setErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
    setButtonLoading(false);
  };

  // This function is responsible for fetching the organizations with help of data present in the search input
  const getSearchItems = async () => {
    try {
      setLoading(true);
      setDataFetched(false);
      if (!searchInput || searchInput === undefined)
        throw new Error("Search input should not be empty");

      // Condition for checking special characters
      let searchString = "";
      for (const char of searchInput) {
        if (/[æøåÆØÅ]/g.test(char)) {
          searchString += char;
        } else if (/[^a-zA-Z0-9 ]/g.test(char)) {
          searchString += `\\\\${char}`;
        } else {
          searchString += char;
        }
      }

      let { data: searchResults } = await axios.get(
        getUrl("REACT_APP_SEARCH_ORGANIZATIONS_URL"),
        {
          params: { keyword: searchString },
        }
      );

      if (searchResults.hits.hits.length === 0)
        throw new Error(`No organization found with ${searchInput}`);

      setDataFetched(true);
      organizationsData.current = searchResults.hits.hits;
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      setErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  };

  // This function is responsible for triggering getSearchItems() function when user enters input value and clicks on Enter button
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      getSearchItems();
    } else if (e.target.value === "") {
      organizationsData.current = [];
    }
  };

  // These functions are responsible for showing Success and Error Modals
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
    // This function is called again in this component when modal is closed to update the state of plan details
    getPlanInfo();
    getFinancialData();
  };
  const hideErrorModal = () => setShowErrorModal(false);

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        message={successMessage}
        hideSuccessModal={hideSuccessModal}
      />
      <ErrorModal
        show={showErrorModal}
        message={errorMessage}
        hideErrorModal={hideErrorModal}
      />

      <div className="search-window-result-table mx-auto">
        <div className="search-window-input-container">
          <input
            type="search"
            placeholder="Search.."
            className="form-input search-window-input p-2"
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <button
            className="search-window-search-button"
            onClick={getSearchItems}
          >
            Search
          </button>
          <span className="search-icon-container">
            <FiSearch size={20} onClick={getSearchItems} />
          </span>
        </div>
        <div className="search-window-results-container">
          {loading ? (
            <div className="d-flex flex-column justify-content-center align-items-center p-5">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <Spinner animation="border" variant="success" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </div>
          ) : (
            <>
              {searchInput && searchInput.length > 0 ? (
                <>
                  {dataFetched &&
                    organizationsData.current.map((organization) => (
                      // List of companies found by the search results
                      <div
                        className="d-flex flex-row justify-content-between align-item-center mx-3 mx-sm-4 py-3"
                        key={organization["_id"]}
                      >
                        <p className="search-company-data my-auto">
                          <span className="search-company-name">
                            {
                              organization["_source"]?.Vrvirksomhed
                                ?.virksomhedMetadata?.nyesteNavn?.navn
                            }
                          </span>
                          <br></br>
                          <span>
                            {
                              organization["_source"]?.Vrvirksomhed
                                ?.virksomhedMetadata?.nyesteBeliggenhedsadresse
                                ?.vejnavn
                            }{" "}
                            {
                              organization["_source"]?.Vrvirksomhed
                                ?.virksomhedMetadata?.nyesteBeliggenhedsadresse
                                ?.postnummer
                            }
                          </span>
                          <span>, </span>
                          <span>
                            {
                              organization["_source"]?.Vrvirksomhed
                                ?.virksomhedMetadata?.nyesteBeliggenhedsadresse
                                ?.postdistrikt
                            }{" "}
                            {
                              organization["_source"].Vrvirksomhed
                                ?.virksomhedMetadata?.nyesteBeliggenhedsadresse
                                ?.husnummerFra
                            }
                          </span>
                        </p>
                        <button
                          className="search-window-add-btn my-auto"
                          onClick={() => onAddOrganization(organization)}
                        >
                          {buttonLoading && storedId === organization["_id"] ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            <span>Add</span>
                          )}
                        </button>
                      </div>
                    ))}
                </>
              ) : (
                <div></div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SearchWindowResults;
