import React from "react";
import { useState, useEffect, useRef } from "react";
import { Row, Col, Spinner, Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import AppExtensionsSDK from "@pipedrive/app-extensions-sdk";

import PostpaidBillingCards from "../components/PostpaidBillingCards";
import ErrorModal from "../components/ErrorModal";
import SuccessModal from "../components/SuccessModal";

import { getUrl } from "../helpers/url";

import { useDirty } from "../context/dirty";
import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";

function Subscription() {
  const {
    cmsInstance,
    message,
    successModal,
    hideSuccModal,
    setErrorModal,
    setMessage,
    setCmsInstance,
  } = useDirty();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [currentPlanDetails, setCurrentPlanDetails] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const effectRan = useRef(false);

  // This function is responsible for getting users plan data
  const fetchPlanData = async () => {
    try {
      setLoading(true);
      // Getting query params by using useSearchParams
      const companyId = searchParams.get("companyId");
      const userId = searchParams.get("userId");

      let { data: subscriptionData } = await axios.get(
        getUrl("REACT_APP_GET_PLAN_DATA_URL"),
        {
          params: { userId, companyId },
        }
      );
      if (!subscriptionData.plan)
        throw new Error(
          "Error while getting plan. Please try again after sometime"
        );
      subscriptionData.plan = subscriptionData.plan.replace("-breached", "");
      setCurrentPlanDetails(subscriptionData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    }
  };

  // This function is responsible for showing Error Modal
  const hideErrorModal = () => setShowErrorModal(false);
  const hideSuccessModal = () => setShowSuccessModal(false);

  // This function is responsible for initializing pipedrive sdk while opening it from iframe
  const initalizePipedriveSDK = async (id) => {
    try {
      await new AppExtensionsSDK({ identifier: id }).initialize({
        size: { width: 1200, height: 700 },
      });
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMessage(message);
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    // Prevented useEffect firing twice in development by using the below condition
    if (effectRan.current === false) {
      const id = searchParams.get("id");
      if (id) {
        initalizePipedriveSDK(id);
      }
      fetchPlanData();
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/subscription") {
      const cmsInstance = async () => {
        const cmsObj = await CmsConstructor({ setMessage, setErrorModal });
        setCmsInstance(cmsObj);
      };
      cmsInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        message={successMessage}
        hideSuccessModal={hideSuccessModal}
      />
      <SuccessModal
        show={successModal}
        message={message}
        hideSuccessModal={hideSuccModal}
      />
      <ErrorModal
        show={showErrorModal}
        message={errorMessage}
        hideErrorModal={hideErrorModal}
      />

      <div className="subscription-page-bg-container">
        {window.location.pathname === "/subscription" && (
          <div className="d-flex flex-column align-items-center">
            <CmsEditAndSave />
          </div>
        )}
        <Container>
          <Row className="d-flex flex-row justify-content-center">
            <Col lg={8} xl={6}>
              <div className="my-5">
                <div>
                  {cmsInstance &&
                    cmsInstance.showRichText(
                      "cmsContent.subscription.headerText",
                      "subscription"
                    )}
                </div>
                {loading || !cmsInstance ? (
                  <div className="d-flex flex-column justify-content-center align-items-center loading-container">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <Spinner
                        animation="border"
                        variant="success"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  </div>
                ) : (
                  <div>
                    <PostpaidBillingCards
                      currentPlanDetails={currentPlanDetails}
                      setCurrentPlanDetails={setCurrentPlanDetails}
                      fetchPlanData={fetchPlanData}
                      setShowErrorModal={setShowErrorModal}
                      setShowSuccessModal={setShowSuccessModal}
                      setErrorMessage={setErrorMessage}
                      setSuccessMessage={setSuccessMessage}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Subscription;
