import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { ImCross } from "react-icons/im";

import PDSelectSearch from "./PDSelectSearch";

function EditMapping({
  mappedItem,
  mappings,
  storedKey,
  onHandlePDSelect,
  editCVRValue,
  editPDValue,
  onCancelMapping,
  onUpdateMapping,
  disablePipedriveValue,
  selectDefaultProperty,
  enableEditing,
  isCVRCheck,
  item,
  pdProperties,
}) {
  return (
    <>
      <td>
        <p className="ms-3 my-auto">{mappedItem[0]}</p>
      </td>
      <td>
        <PDSelectSearch
          // Dropdown for Pipedrive fields
          disablePipedriveValue={disablePipedriveValue}
          enableEditing={enableEditing}
          onHandlePDSelect={onHandlePDSelect}
          selectDefaultProperty={selectDefaultProperty}
          mappedItem={mappedItem}
          isCVRCheck={isCVRCheck}
          mappings={mappings}
          editCVRValue={editCVRValue}
          item={item}
          pdProperties={pdProperties}
        />
      </td>
      <td className="p-0 d-flex flex-row justify-content-end">
        {(storedKey !== editCVRValue[0] && editCVRValue.length > 0) ||
        (isCVRCheck && Object.keys(editPDValue).length > 0) ||
        (Object.keys(editPDValue).length > 0 &&
          selectDefaultProperty(mappedItem[1]).value !== editPDValue.value) ? (
          <button
            className="add-icon-button"
            onClick={() => onUpdateMapping(item, mappedItem[0])}
          >
            <AiOutlineCheck className="add-icon" />
          </button>
        ) : (
          ""
        )}
        {isCVRCheck ? (
          ""
        ) : (
          <button className="delete-icon-button" onClick={onCancelMapping}>
            <ImCross className="delete-icon" />
          </button>
        )}
      </td>
    </>
  );
}

export default EditMapping;
