import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";

import { onSaveMappings } from "./Configurator";
import { fetchMappings } from "../pages/MappingWindow";

import { useDirty } from "../context/dirty";

const SaveModal = ({ show, hideSaveModal }) => {
  const [loading, setLoading] = useState(false);
  const [discardBtnLoading, setDiscardBtnLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const {
    mappings,
    defaultMappings,
    setIsDirty,
    setMessage,
    setErrorModal,
    setSuccessModal,
    setMappings,
    deletedPipedriveFields,
    isCVRCheck,
    isDirty,
  } = useDirty();

  const discardChanges = async () => {
    setDiscardBtnLoading(true);
    await fetchMappings({
      searchParams,
      setMappings,
      setLoading,
      setMessage,
      setErrorModal,
    });
    setDiscardBtnLoading(false);
    hideSaveModal();
  };

  const saveChanges = async () => {
    setLoading(true);
    await onSaveMappings({
      setLoading,
      mappings,
      defaultMappings,
      searchParams,
      setIsDirty,
      setMessage,
      setSuccessModal,
      setErrorModal,
    });
    setLoading(false);
    setDiscardBtnLoading(false);
    hideSaveModal();
  };

  const renderText = () => {
    if (isCVRCheck) {
      return "Please Map the CVR Number as it is required property to proceed further.";
    } else if (deletedPipedriveFields.length > 0) {
      return "Please remap the fields for which custom property has been deleted.";
    } else if (isDirty) {
      return "You've made changes that haven't been saved yet. Please either save or discard these changes to continue.";
    }
  };

  const renderBtns = () => {
    if (isCVRCheck) {
      return (
        <button
          className="delete-modal-save-changes-btn"
          onClick={() => {
            window.scrollTo(0, 350);
            hideSaveModal();
          }}
        >
          Edit
        </button>
      );
    } else if (deletedPipedriveFields.length > 0) {
      <button
        className="delete-modal-save-changes-btn"
        onClick={() => {
          window.scrollTo(0, 400);
          hideSaveModal();
        }}
      >
        Edit
      </button>;
    } else if (isDirty) {
      return (
        <>
          <button
            className="me-3 delete-modal-delete-btn"
            onClick={() => discardChanges()}
          >
            {discardBtnLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Discard</span>
            )}
          </button>
          <button
            onClick={() => saveChanges()}
            className="delete-modal-save-changes-btn"
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Save changes</span>
            )}
          </button>
        </>
      );
    }
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <p>{renderText()}</p>
        <div className="d-flex flex-row justify-content-end">
          {renderBtns()}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SaveModal;
