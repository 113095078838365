import React from "react";
import { Modal } from "react-bootstrap";

import { useDirty } from "../context/dirty";

const DeleteModal = ({ show, hideDeleteModal, onDeleteExistingMapping }) => {
  const { cmsInstance } = useDirty();
  return (
    <div>
      <Modal
        className="my-modal"
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="delete-modal p-4">
          {cmsInstance &&
            cmsInstance.showRichText(
              "cmsContent.notifications.mappingWindow.successContent.deleteMapping.message",
              "delete-modal-message"
            )}
          <div className="d-flex flex-row justify-content-end mt-3">
            <button
              className="me-3 delete-modal-cancel-btn"
              onClick={hideDeleteModal}
            >
              Cancel
            </button>
            <button
              className="delete-modal-delete-btn"
              onClick={() => {
                onDeleteExistingMapping();
                hideDeleteModal();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
