import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import { useDirty } from "../context/dirty";

function SuccessModal({ show, message, hideSuccessModal }) {
  const { cmsInstance } = useDirty();
  useEffect(() => {
    // Added timer for hiding success modal
    if (show) {
      const timer = setTimeout(() => {
        hideSuccessModal();
      }, 3000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const checkContentFrom = () => {
    const notificationMessage = message.split(".");
    const messageFrom = notificationMessage.includes("cmsContent");
    return messageFrom;
  };

  return (
    <div>
      <Modal className="my-modal" onHide={hideSuccessModal} show={show}>
        <div className="success-modal">
          <div className="d-flex flex-row justify-content-end">
            <Button className="success-modal-button" onClick={hideSuccessModal}>
              ×
            </Button>
          </div>
          {checkContentFrom() ? (
            <>
              {cmsInstance &&
                cmsInstance.showRichText(message, "success-modal-message")}
            </>
          ) : (
            <p className="success-modal-message mb-4 mx-3">{message}</p>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default SuccessModal;
