import React from "react";
import { Table, Spinner } from "react-bootstrap";

function FinancialDataTable({ financialData, isLoading }) {
  // Financial Keys
  const financialKeys = [
    "Net turnover",
    "Gross profit",
    "Net result",
    "Equity",
    "Balance sheet",
    "Currency code",
  ];

  return (
    <div>
      {isLoading ? (
        <div className="d-flex flex-column justify-content-center align-items-center p-5">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Spinner animation="border" variant="success" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          {financialData && Object.keys(financialData).length > 0 && (
            <div>
              <h3 className="search-window-heading">Financial Data</h3>
              <p className="financial-note-text">
                (Data represented in the table is in thousands. eg: 288 is
                288,000.)
              </p>
              <Table
                className="financial-table"
                striped
                borderless
                responsive={true}
              >
                <thead>
                  <tr>
                    <th></th>
                    {Object.keys(financialData)
                      .sort()
                      .reverse()
                      .map((year) => (
                        <th key={year} className="text-center">
                          {year}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {financialKeys.map((key) => (
                    <tr key={key}>
                      <td>{key}</td>
                      {Object.keys(financialData)
                        .sort()
                        .reverse()
                        .map((year) => (
                          <td key={year} className="text-center">
                            {financialData[`${year}`][key]}
                          </td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr className="my-4" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FinancialDataTable;
