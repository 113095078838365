import React from "react";
import { Spinner } from "react-bootstrap";

import SuccessModal from "./SuccessModal";

import { useDirty } from "../context/dirty";

function AddCompanyInfo() {
  const { cmsInstance, successModal, message, hideSuccModal } = useDirty();
  return (
    <>
      <SuccessModal
        show={successModal}
        message={message}
        hideSuccessModal={hideSuccModal}
      />
      {!cmsInstance ? (
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Spinner animation="border" variant="success" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        </div>
      ) : (
        <div id="addingOrganization">
          {cmsInstance &&
            cmsInstance.showRichText(
              "cmsContent.confirmationPage",
              "confirmation"
            )}
        </div>
      )}
    </>
  );
}

export default AddCompanyInfo;
