import React from "react";
import { Navbar, Button, Spinner, Container } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

import ErrorModal from "../components/ErrorModal";
import MappingWindow from "./MappingWindow";
import Subscription from "./Subscription";
import Confirmation from "./Confirmation";

import { CmsConstructor } from "../helpers/cmsConstructor";
import CmsEditAndSave from "../helpers/CmsEditAndSave";
import { useDirty } from "../context/dirty";

import { getUrl } from "../helpers/url";

function NavBarPage() {
  const {
    isDirty,
    setCmsInstance,
    setMessage,
    setErrorModal,
    cmsInstance,
    isEdit,
    setShowSaveModal,
    deletedPipedriveFields,
    isCVRCheck,
  } = useDirty();
  const [currentWindow, setCurrentWindow] = useState("");
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const effectRan = useRef(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const WINDOWS = Object.freeze({
    MAPPING_WINDOW: "Mapping Window",
    SUBSCRIPTION_WINDOW: "Subscription Window",
    CONFIRMATION_WINDOW: "Confirmation Window",
  });

  const windows = {
    [WINDOWS.MAPPING_WINDOW]: <MappingWindow />,
    [WINDOWS.SUBSCRIPTION_WINDOW]: <Subscription />,
    [WINDOWS.CONFIRMATION_WINDOW]: <Confirmation />,
  };

  // This function is responsible for navigating components inside the setup page by clicking on next button
  const onNextNavigation = () => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    window.scrollTo(0, 0);
    if (currentWindow === WINDOWS.SUBSCRIPTION_WINDOW) {
      navigate(
        `${window.location.pathname}?companyId=${companyId}&userId=${userId}`
      );
      setCurrentWindow(WINDOWS.MAPPING_WINDOW);
    }
    if (currentWindow === WINDOWS.MAPPING_WINDOW) {
      navigate(
        `${window.location.pathname}?companyId=${companyId}&userId=${userId}&confirmation=true`
      );
      setCurrentWindow(WINDOWS.CONFIRMATION_WINDOW);
    }
  };

  // This function is responsible for navigating components inside the setup page by clicking on back button
  const onBackNavigation = () => {
    const companyId = searchParams.get("companyId");
    const userId = searchParams.get("userId");
    window.scrollTo(0, 0);
    if (currentWindow === WINDOWS.CONFIRMATION_WINDOW) {
      navigate(
        `${window.location.pathname}?companyId=${companyId}&userId=${userId}`
      );
      setCurrentWindow(WINDOWS.MAPPING_WINDOW);
    }
    if (currentWindow === WINDOWS.MAPPING_WINDOW) {
      navigate(
        `${window.location.pathname}?companyId=${companyId}&userId=${userId}&paymentStatus=none`
      );
      setCurrentWindow(WINDOWS.SUBSCRIPTION_WINDOW);
    }
  };

  // This function responsible for navigating the user to pipedrive website when clicked on finish button
  const goBackToPipedrive = () => {
    const pdCompanyDomain = JSON.parse(
      sessionStorage.getItem("pdCompanyDomain")
    );
    window.open(`https://${pdCompanyDomain}.pipedrive.com/`, "_self");
  };

  // This function is responsible for installing the user for the first time
  const install = async () => {
    try {
      const code = searchParams.get("code");
      const companyId = searchParams.get("companyId");
      const userId = searchParams.get("userId");
      const paymentStatus = searchParams.get("paymentStatus");
      const confirmation = searchParams.get("confirmation");
      // Rendering the components inside this setup page based on the params present in the url
      if (code) {
        setLoading(true);
        let { data: res } = await axios.get(getUrl("REACT_APP_INSTALL_URL"), {
          params: {
            code,
          },
        });
        sessionStorage.setItem(
          "pdCompanyDomain",
          JSON.stringify(res.companyDomain)
        );
        setCurrentWindow(WINDOWS.SUBSCRIPTION_WINDOW);
        setLoading(false);
        navigate(
          `../setup?companyId=${res.companyId}&userId=${res.userId}&paymentStatus=none`
        );
      } else if (companyId && userId && confirmation) {
        setCurrentWindow(WINDOWS.CONFIRMATION_WINDOW);
      } else if (companyId && userId && paymentStatus) {
        setCurrentWindow(WINDOWS.SUBSCRIPTION_WINDOW);
      } else if (companyId && userId) {
        setCurrentWindow(WINDOWS.MAPPING_WINDOW);
      }
    } catch (error) {
      console.log(error);
      let message;
      if (error.response && error.response.data.message)
        message = error.response.data.message;
      else message = error.message;
      setErrorMsg(message);
      setShowErrorModal(true);
    }
  };

  // This function is responsible for showing Error Modal
  const hideErrorModal = () => setShowErrorModal(false);

  const showSaveModal = () => {
    setShowSaveModal(true);
  };

  useEffect(() => {
    // Prevented useEffect firing twice in development by using the below condition
    if (effectRan.current === false) {
      install();
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cmsInstance = async () => {
      const cmsObj = await CmsConstructor({ setMessage, setErrorModal });
      setCmsInstance(cmsObj);
    };
    cmsInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ErrorModal
        show={showErrorModal}
        message={errorMsg}
        hideErrorModal={hideErrorModal}
      />
      <div>
        {loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center nav-loading-container">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Spinner animation="border" variant="success" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        ) : (
          <div className="navbar-page-bg-container">
            <Navbar fixed="top" className="px-4 py-3 navbar">
              <div className="d-flex justify-content-between w-100">
                {currentWindow !== "Subscription Window" ? (
                  <Button
                    className="navbar-back-button my-auto"
                    onClick={onBackNavigation}
                  >
                    Back
                  </Button>
                ) : (
                  <p className="disable-para"></p>
                )}
                <div className="text-center header-content">
                  {cmsInstance &&
                    cmsInstance.showRichText("cmsContent.header.text")}
                </div>
                {currentWindow !== "Confirmation Window" ? (
                  <>
                    {(isDirty ||
                      deletedPipedriveFields.length > 0 ||
                      isCVRCheck) &&
                    currentWindow === "Mapping Window" ? (
                      <Button
                        className="navbar-back-button my-auto"
                        onClick={() => showSaveModal()}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        className="navbar-back-button my-auto"
                        onClick={onNextNavigation}
                      >
                        Next
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    className="navbar-finish-button my-auto"
                    onClick={goBackToPipedrive}
                  >
                    Finish
                  </Button>
                )}
              </div>
            </Navbar>

            <Container className="components-container">
              <div
                className="d-flex flex-column align-items-center"
                style={{
                  marginTop:
                    isEdit && window.location.pathname === "/setup" && "150px",
                }}
              >
                <CmsEditAndSave />
              </div>
              <div>{windows[currentWindow]}</div>
            </Container>
          </div>
        )}
      </div>
    </>
  );
}

export default NavBarPage;
